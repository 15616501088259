import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgExternalLink: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.977 5H19M19 5v5.023M19 5l-6.306 6.305M19 13.87v2.779A2.372 2.372 0 0116.649 19H7.35A2.372 2.372 0 015 16.649V7.35A2.372 2.372 0 017.351 5h2.779"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgExternalLink
