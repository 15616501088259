import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCloseCircle: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} fill="#ececee" r={12} />
      <path
        d="M12.569 11.997l3.317-3.317a.4.4 0 00-.566-.566l-3.317 3.317-3.317-3.317a.4.4 0 00-.566.566l3.317 3.317-3.317 3.317a.4.4 0 10.566.566l3.317-3.317 3.317 3.317a.4.4 0 00.566-.566z"
        fill="#3e4153"
      />
    </Icon>
  )
}
export default SvgCloseCircle
