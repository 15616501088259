import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNearbyWalk: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.083 6a2 2 0 100-4 2 2 0 000 4zm-1.947.606a1 1 0 011.028.08l1 .715a1 1 0 01.401 1.002l-1.049 5.444 4.4 6.598a1 1 0 01-1.665 1.11l-4.626-6.94-.816-1.223a1 1 0 01-.153-.729l.597-3.38-1.785.893-.572 2.857a1 1 0 01-1.961-.392l.667-3.337a1 1 0 01.534-.698l4-2zm5.63 6.343l-2.874-.958.382-1.981 3.125 1.041a1 1 0 01-.632 1.898zm-10.555 7.56L9.48 14.7l.314.47.931 1.397-2.77 4.923a1 1 0 01-1.743-.98z"
        clipRule="evenodd"
      />
    </Icon>
  )
}
export default SvgNearbyWalk
