import styled, { DefaultTheme } from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { Direction } from '@/ui/typings/direction'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { ListProps } from './'

const getDividerStyle = (
  theme: DefaultTheme,
  { $variant, $spacing }: Pick<ListBaseProps, '$variant' | '$spacing'>
) => {
  const baseDividerStyle = `
    content: "";
    position: absolute;
    background-color: ${theme.colors.grey.light4};
  `

  switch ($variant) {
    case Direction.vertical:
      return `
        row-gap: calc(${$spacing} * 0.5);
        & > li:not(:last-of-type) {
          padding-bottom: calc(${$spacing} * 0.5);
          &::after {
            ${baseDividerStyle}
            width: 100%;
            height: 0.1rem;
            left: 0;
            bottom: 0;
          }
        }
      `

    case Direction.horizontal:
      return `
        column-gap: calc(${$spacing} * 0.5);
        & > li:not(:last-of-type) {          
          padding-right: calc(${$spacing} * 0.5);
          &::after {
            ${baseDividerStyle}
            width: 0.1rem;
            height: 100%;
            right: 0;
            bottom: 0;
          }
        }
      `

    default:
      return ``
  }
}

type ListBaseProps = RequiredPickMap<
  ListProps,
  'hasDivider' | 'fontSize' | 'variant' | 'spacing'
>

export const ListBase = styled.ul<ListBaseProps>(
  ({ theme, $fontSize, $hasDivider, $variant, $spacing }) => {
    /** Styles applied to any and all combinations of props */
    const commonStyles = `
      font-family: inherit;
      ${styleFromTypography(theme.typography.body[$fontSize])}

      & {  
        list-style: none;
        margin: 0 0 0 0;
        padding: 0;

        li {
          position: relative;
        }
      }
    `

    /** Styles based on variant === "horizontal" or "vertical" */
    let variantStyles = ''
    switch ($variant) {
      case Direction.vertical:
        variantStyles = `
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: ${$spacing};
        `
        break

      case Direction.horizontal:
        variantStyles = `
          height: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          column-gap: ${$spacing};
          row-gap: ${theme.spacing.default};
        `
        break

      default:
        variantStyles = ''
        break
    }

    return `
        ${commonStyles}
        ${variantStyles}
        ${$hasDivider ? getDividerStyle(theme, { $variant, $spacing }) : ``}
    `
  }
)
