import {
  type UserLocation,
  useGetLocationFromCoordinatesLazyQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

import { getLocationFromCoordinatesResult } from '@/domain/location/getLocationFromCoordinatesResult'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * The returned function fetches the location from given coordinates.
 * Parses the returned location into a Location object to be used by the location modal.
 * @returns Function that fetches location given its coordinates
 */
export const useFetchLocationFromCoordinates = () => {
  const [getLocationFromCoordinates] = useGetLocationFromCoordinatesLazyQuery()

  /**
   * Function to fetch location from coordinates
   * @param latitude Latitude of location
   * @param longitude Longitude of location
   * @returns Location object
   */
  const fetchLocationFromCoordinates = useCallback(
    async ({
      latitude,
      longitude,
    }: {
      latitude: number
      longitude: number
    }): Promise<UserLocation | null | undefined> => {
      try {
        const { data, error } = await getLocationFromCoordinates({
          variables: {
            coordinates: { latitude, longitude },
          },
        })

        if (error || !data?.locationFromCoordinates) {
          const errorMessage = error?.message || 'unexpected `locationFromCoordinates` data'
          sendToLogger(errorMessage, {
            tags: {
              fn: 'fetchLocationFromCoordinates',
              component: 'useFetchLocationFromCoordinates',
            },
            fingerprint: ['useFetchLocationFromCoordinates', 'missingData'],
          })
          return
        }

        return getLocationFromCoordinatesResult(data.locationFromCoordinates, {
          latitude,
          longitude,
        })
      } catch (error) {
        sendToLogger('Unknown error occurred', {
          tags: {
            fn: 'fetchLocationFromCoordinates',
            component: 'useFetchLocationFromCoordinates',
          },
          fingerprint: ['useFetchLocationFromCoordinates'],
        })
      }
    },
    [getLocationFromCoordinates]
  )

  return { fetchLocationFromCoordinates }
}
