import { type UserLocation } from '@kijiji/generated/graphql-types'

export const areLocationsEquivalent = (
  previousLocation: UserLocation,
  newLocation: UserLocation
): boolean => {
  return Object.keys(newLocation).every((locationProperty) => {
    if (locationProperty === 'name') {
      const newLocationName = newLocation['name']
      const previousLocationName = previousLocation['name']

      return newLocationName?.en_CA === previousLocationName?.en_CA
    }

    if (locationProperty === 'area') {
      if (previousLocation['area'] && newLocation['area']) {
        return (
          previousLocation.area.address === newLocation.area.address &&
          previousLocation.area.latitude === newLocation.area.latitude &&
          previousLocation.area.longitude === newLocation.area.longitude &&
          previousLocation.area.radius === newLocation.area.radius
        )
      }

      if (
        (previousLocation['area'] && !newLocation['area']) ||
        (!previousLocation['area'] && newLocation['area'])
      ) {
        return false
      }
    }

    return (
      newLocation[locationProperty as keyof UserLocation] ===
      previousLocation[locationProperty as keyof UserLocation]
    )
  })
}
