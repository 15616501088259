import {
  AuthModalVariant,
  GetAuthModalStateDocument,
  useGetAuthModalStateQuery,
} from '@kijiji/generated/graphql-types'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  AuthModalContent,
  AuthModalFooter,
  AuthModalIconContainer,
  AuthModalPanel,
  AuthModalRegisterMessage,
  AuthModalTitle,
} from '@/features/auth/components/auth-modal/styled'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { Button } from '@/ui/atoms/button'
import { Modal } from '@/ui/molecules/modal/Modal'
import signInImage from '~/public/next-assets/images/sign-in-modal-icon.png'

export const AuthModal = () => {
  const { data: { authModalState } = {}, client } = useGetAuthModalStateQuery()

  const { t: tCommon } = useTranslation(TRANSLATION_KEYS.COMMON)
  const { t: tRoutes } = useTranslation(TRANSLATION_KEYS.ROUTES)
  const { t: tFavourites } = useTranslation(TRANSLATION_KEYS.FAVOURITES)

  const isModalOpen = authModalState?.isModalOpen || false
  const callbackUrl = authModalState?.callbackUrl || undefined
  const variant = authModalState?.modalVariant

  let titleText,
    bodyText = ''

  switch (variant) {
    case AuthModalVariant.LoginToFavourite: {
      titleText = tFavourites('favourites.login_modal.title')
      bodyText = tFavourites('favourites.login_modal.body_text')
      break
    }
    default: {
      // Default no context login prompt should go here
      bodyText = tFavourites('favourites.login_modal.body_text')
      titleText = tFavourites('favourites.login_modal.title')
    }
  }

  const registerHref = tRoutes('register.href')

  const [goToSignIn, setGoToSignIn] = useState(false)
  useSignin({ callbackUrl: callbackUrl, goToSignIn: goToSignIn })

  const handleAuthClick = () => {
    setGoToSignIn(true)
  }

  const handleCloseAuthModal = () => {
    client.cache.writeQuery({
      query: GetAuthModalStateDocument,
      data: {
        authModalState: {
          isModalOpen: false,
          modalVariant: null,
          callbackUrl: null,
        },
      },
    })
  }

  return (
    <Modal
      data-testid="sign-in-favourite-modal"
      id="sign-in-modal"
      isOpen={isModalOpen}
      onCancel={handleCloseAuthModal}
      label={titleText}
      hasCloseButton
      closeButtonLabel={tCommon('modals.login.labels.close_login')}
      width={{ small: '100vw', medium: '40rem' }}
    >
      <AuthModalPanel>
        <AuthModalIconContainer>
          <Image aria-hidden="true" src={signInImage} alt="" width={210} />
        </AuthModalIconContainer>

        <AuthModalTitle>{titleText}</AuthModalTitle>
        <AuthModalContent>{bodyText}</AuthModalContent>
        <AuthModalFooter>
          <Button
            data-testid="sign-in-modal-button"
            isFullWidth
            onClick={handleAuthClick}
            variant="primary"
          >
            {tCommon('modals.login.labels.sign_in_button')}
          </Button>
          <AuthModalRegisterMessage>
            {tCommon('modals.login.labels.create_account_title')}{' '}
            <LinkCustom href={registerHref} variant="primary">
              {tCommon('modals.login.labels.create_account_link_text')}
            </LinkCustom>
          </AuthModalRegisterMessage>
        </AuthModalFooter>
      </AuthModalPanel>
    </Modal>
  )
}
