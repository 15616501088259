import CloseIcon from '@kijiji/icons/src/icons/Close'
import { FC, HTMLAttributes, ReactNode } from 'react'

import { BodyText } from '@/ui/atoms/body-text'
import { Orientation } from '@/ui/typings/orientation'

import {
  TooltipCloseButton,
  TooltipContainer,
  TooltipIconContainer,
  TooltipTrigger,
  TooltipWrapper,
} from './styled'

export type TooltipProps = {
  /**
   * Defines the style of the tooltip
   * @default 'light'
   */
  variant?: 'light' | 'dark'
  /**
   * Defines orientation
   * @default 'top-left'
   */
  orientation: Orientation
  /**
   * Defines z-index
   */
  zIndex?: number
  /**
   * Defines the tooltip icon
   */
  tooltipIcon?: ReactNode
  /**
   * Defines tooltip id.
   * Note: Id is important for tooltip to function properly.
   * To use tooltip, pass your component or element as child prop to tooltip
   * including all other required props
   */
  tooltipId: string
  /**
   * Defines the text that show on hover
   */
  tooltipText: string | ReactNode
  /**
   * This determines if tooltip should show by default or on hover.
   * To show tooltip by default set isVisible to true else it shows on hover
   */
  isVisible?: boolean
  /**
   * Optional dismiss callback function. Adds a cancel button (x) to the top right corner of the tooltip.
   */
  onClose?: () => void
  /**
   * Defines aria label for close icon button
   */
  closeButtonLabel?: string
} & HTMLAttributes<HTMLElement>

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipIcon,
  variant = 'light',
  orientation = 'top-right',
  zIndex,
  isVisible = undefined,
  tooltipId,
  tooltipText,
  onClose = undefined,
  closeButtonLabel = '',
  ...rest
}: TooltipProps) => {
  return (
    <TooltipWrapper>
      <TooltipTrigger aria-describedby={tooltipId} isVisible={isVisible}>
        {children}
      </TooltipTrigger>
      <TooltipContainer
        role="tooltip"
        variant={variant}
        orientation={orientation}
        zIndex={zIndex}
        id={tooltipId}
        isVisible={isVisible}
        {...rest}
      >
        {tooltipIcon && (
          <TooltipIconContainer data-testid="tool-tip-icon">
            {tooltipIcon}
          </TooltipIconContainer>
        )}

        {typeof tooltipText === 'string' ? (
          <BodyText data-testid="tool-tip-text" size="small">
            {tooltipText}
          </BodyText>
        ) : (
          tooltipText
        )}

        {!!onClose && (
          <TooltipCloseButton onClick={onClose} aria-label={closeButtonLabel}>
            <CloseIcon aria-hidden />
          </TooltipCloseButton>
        )}
      </TooltipContainer>
    </TooltipWrapper>
  )
}
