import { GetPsaDismissedDocument, useGetPsaDismissedQuery } from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { isPSABannerVisible } from '@/components/shared/psa-banner/isPSABannerVisible'
import { CookieRegistry } from '@/constants/cookieRegistry'
import { type PSAKeys, PSA_BANNER } from '@/constants/psaBanner'
import { createCookie } from '@/utils/cookies/createCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

export const usePSABanner = (psaKey?: PSAKeys) => {
  const { asPath } = useRouter()
  const { t } = useTranslation('common')
  const campaignHref = psaKey ? t(`psa_banner.${PSA_BANNER[psaKey].intlKey}.link.href`) : ''

  const { data, client } = useGetPsaDismissedQuery({
    skip: !psaKey,
    ssr: true,
  })

  const createDismissPSACookie = () => {
    if (!psaKey) return

    const existingCookie = getCookieByKey(document.cookie, CookieRegistry.HAS_DISMISSED_PSA_BANNER)

    const decodedExistingCookie = decodeURIComponent(existingCookie)
    const deserialized = decodedExistingCookie ? `${decodedExistingCookie},${psaKey}` : psaKey
    const encoded = encodeURIComponent(deserialized)
    createCookie(CookieRegistry.HAS_DISMISSED_PSA_BANNER, encoded, { expiryInDays: 365 })
  }

  const handlePSAClose = () => {
    if (!psaKey) return

    client.cache.writeQuery({
      query: GetPsaDismissedDocument,
      data: {
        dismissedStates: {
          ...data?.dismissedStates,
          [psaKey]: true,
        },
      },
    })

    createDismissPSACookie()
  }

  return {
    isVisible: !psaKey ? false : isPSABannerVisible({ asPath, campaignHref, data, psaKey }),
    handlePSAClose,
  }
}
