import type { ThemeProps } from '@kijiji/theme'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

import { TRANSITION_STYLE } from './commonStyles'

export const INPUT_BORDER_COLOR = (theme: ThemeProps) =>
  theme.colors.grey.light3
export const INPUT_BORDER_WIDTH = '.1rem'
export const INPUT_ERROR_COLOR = (theme: ThemeProps) => theme.colors.red.dark1
export const INPUT_HORIZONTAL_PADDING = '1.6rem'

export const COMMON_INPUT_STYLE = (theme: ThemeProps) => `
  ${TRANSITION_STYLE}
  ${styleFromTypography(theme.typography.body.medium)}
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: ${INPUT_BORDER_WIDTH} solid ${INPUT_BORDER_COLOR(theme)};
  color: inherit;
  font-family: inherit;
  height: 4.8rem;
  outline: none;
  padding: 2.5rem ${INPUT_HORIZONTAL_PADDING} 1rem;
  width: 100%;

  &:focus,
  &:active {
    border-color: ${theme.colors.blue.primary};
  }

  &[aria-invalid='true'] {
    border-color: ${INPUT_ERROR_COLOR(theme)};
  }
`
