import { type UserLocation } from '@kijiji/generated/graphql-types'

import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'
import { type ApiLocale } from '@/domain/locale'
import { kilometresToMetres } from '@/utils/conversions'

/**
 * Converts a location of type UserLocation to a location of type RecentSearchLocation for storage in the RecentSearchLocations localStorage array
 * @param userLocation
 * @param searchAll
 * @param locale
 * @returns
 */
export const getRecentSearchLocationFromLocation = (
  userLocation: UserLocation,
  searchAll: boolean,
  locale: ApiLocale
): RecentSearchLocation | undefined => {
  const locationName = userLocation.name[locale] ?? ''
  const radius = userLocation.area?.radius
    ? kilometresToMetres(userLocation.area.radius)
    : undefined

  return {
    address: userLocation.area?.address ?? locationName ?? undefined,
    internalId: userLocation.id,
    internalName: locationName,
    isProvinceOrCountry: !searchAll && userLocation.isRegion,
    isSearchAllArea: searchAll,
    latitude: userLocation.area?.latitude ?? undefined,
    longitude: userLocation.area?.longitude ?? undefined,
    mapRadius: radius,
  }
}
