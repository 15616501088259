/**
 * Checks if the input is a valid positive integer (including zero).
 *
 * @param {unknown} value - The value to check. Can be of any type.
 * @returns {boolean} True if the value is a positive integer or zero, false otherwise.
 *
 * @example
 * isPositiveInteger(5);    // returns true
 * isPositiveInteger(0);    // returns true
 * isPositiveInteger(-1);   // returns false
 * isPositiveInteger(3.14); // returns false
 * isPositiveInteger('5');  // returns false
 */
export const isPositiveInteger = (value: unknown): value is number => {
  return typeof value === 'number' && Number.isInteger(value) && value >= 0;
};
