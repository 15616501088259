import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

export const AuthModalPanel = styled.div(
  ({ theme }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
  `
)

export const AuthModalIconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 4.2rem;
  padding-top: 4.8rem;
`

export const AuthModalTitle = styled(BodyText)(
  ({ theme }) => `
  font-size: 2.2rem;
  padding-bottom: ${theme.spacing.defaultSmall};
  text-align: center;
`
)

export const AuthModalContent = styled.div(
  ({ theme }) => `
    color: ${theme.colors.grey.light2};
    font-size: ${theme.spacing.default};
    text-align: center;
`
)

export const AuthModalFooter = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${theme.spacing.default};
  padding-bottom: ${theme.spacing.large};
  padding-top: ${theme.spacing.large};
`
)

export const AuthModalRegisterMessage = styled.p(
  ({ theme }) => `
    color: ${theme.colors.grey.light2};
    padding-top: ${theme.spacing.large};
  `
)
