import { showReportDialog } from '@sentry/browser'
import { type ErrorInfo, type ReactElement, type ReactNode, useCallback, useState } from 'react'
import { type FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { sendToLogger } from '@/utils/sendToLogger'

interface ErrorFallbackProps extends FallbackProps {
  eventId?: string
}

const ErrorFallback = ({
  error,
  resetErrorBoundary,
  eventId,
}: ErrorFallbackProps): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        textAlign: 'center',
      }}
    >
      <h2 style={{ color: '#333', fontSize: '2rem' }}>An unexpected error has occurred.</h2>
      <p style={{ color: 'red', marginBottom: '20px' }}>{error.message}</p>

      <button
        onClick={resetErrorBoundary}
        style={{
          margin: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: 'rgb(55, 51, 115)',
          border: 'none',
          borderRadius: '0.4rem',
          color: '#fff',
          transition: 'background-color 0.3s',
        }}
      >
        Try again
      </button>

      <button
        onClick={() => showReportDialog({ eventId })}
        style={{
          margin: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: '#f44336',
          border: 'none',
          borderRadius: '0.4rem',
          color: '#fff',
          transition: 'background-color 0.3s',
        }}
      >
        Help fix this issue
      </button>
    </div>
  )
}

type ErrorBoundaryProps = {
  onError?: (error: Error, errorInfo: ErrorInfo) => void
  fallbackRender?: (props: FallbackProps) => ReactNode
  extra?: Record<string, unknown>
  fingerprintId?: string
  children: React.ReactNode
}

/**
 * ErrorBoundary component to handle errors and provide a fallback UI.
 *
 * @param onError - A callback function to handle errors.
 * @param fallbackRender - A React component to render as a fallback.
 * @param extra - Extra data to send to Sentry.
 * @returns The React ErrorBoundary component.
 */
export const ErrorBoundary = ({
  onError,
  fallbackRender,
  extra,
  fingerprintId = 'ErrorBoundary',
  ...rest
}: ErrorBoundaryProps): ReactElement => {
  const [eventId, setEventId] = useState<string | undefined>(undefined)

  const onErrorHandle = (error: Error, errorInfo: ErrorInfo): void => {
    if (onError) {
      onError(error, errorInfo)
    }

    // Capture the exception with Sentry and include the React component stack
    // const sentryEventId = captureException(error, {
    const sentryEventId = sendToLogger(error, {
      fingerprint: [fingerprintId],
      contexts: { react: { componentStack: errorInfo.componentStack } },
      ...(extra ? { extra } : {}),
    })

    if (sentryEventId) {
      setEventId(sentryEventId)
    }
  }

  const fallbackRenderHandle = useCallback(
    (props: FallbackProps) => {
      return <ErrorFallback eventId={eventId} {...props} />
    },
    [eventId]
  )

  return (
    <ReactErrorBoundary onError={onErrorHandle} fallbackRender={fallbackRenderHandle} {...rest} />
  )
}
