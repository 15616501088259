import { useTheme } from 'styled-components'

import { INPUT_ERROR_COLOR } from '@/ui/constants/commonFormStyle'
import { isEmpty } from '@/ui/helpers/string'

import { FormErrorIcon, FormErrorText } from './styled'

export type FieldErrorProps = {
  /**
   * Error messages to display
   */
  error?: string
  /**
   * Id used for linking the error to the input
   */
  id?: string
}

/**
 * @description Field errors component that displays error messages
 */
export const FieldError = ({ error, id }: FieldErrorProps) => {
  const theme = useTheme()
  const hasErrors = !isEmpty(error)

  if (!hasErrors) return null

  return (
    <FormErrorText
      aria-live="polite"
      color={INPUT_ERROR_COLOR(theme)}
      id={id}
      size="small"
    >
      <FormErrorIcon data-testid="svg-icon" aria-hidden />
      {error}
    </FormErrorText>
  )
}
