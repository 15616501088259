import { forwardRef } from 'react'

import { CommonInputProps } from '@/ui/typings/commonTextInput'

import { StyledTextInput } from './styled'

export type TextInputProps = CommonInputProps

/**
 * Base input to build text input fields
 */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ errorId, id, ...rest }: TextInputProps, ref) => {
    return (
      <StyledTextInput
        aria-describedby={errorId}
        aria-invalid={!!errorId}
        data-testid={`text-input-${id}`}
        id={id}
        ref={ref}
        type="text"
        {...rest}
      />
    )
  }
)
TextInput.displayName = 'TextInput'
