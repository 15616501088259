import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLocationServiceOn: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.78 5.22a.77.77 0 00-.8-.17L5.52 9.84a.76.76 0 00-.52.75.77.77 0 00.58.71l5.74 1.38 1.38 5.74a.77.77 0 00.71.58.75.75 0 00.72-.51L19 6a.771.771 0 00-.22-.78z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgLocationServiceOn
