import { CATEGORIES } from '@kijiji/category'

export const TRENDING_CATEGORIES = [
  // JAN - Snowmobiles
  {
    categoryId: CATEGORIES.SNOWMOBILES_CATEGORY_ID,
    url: '/b-snowmobile/c313',
    urlFr: '/b-motoneige/c313',
  },
  // FEB - Snowmobiles
  {
    categoryId: CATEGORIES.SNOWMOBILES_CATEGORY_ID,
    url: '/b-snowmobile/c313',
    urlFr: '/b-motoneige/c313',
  },
  // MAR - ATVs
  {
    categoryId: CATEGORIES.ATVS_CATEGORY_ID,
    url: '/b-atv/c311',
    urlFr: '/b-vtt/c311',
  },
  // APR - ATVs
  {
    categoryId: CATEGORIES.ATVS_CATEGORY_ID,
    url: '/b-atv/c311',
    urlFr: '/b-vtt/c311',
  },
  // MAY - Home - Outdoor & Garden
  {
    categoryId: CATEGORIES.HOME_OUTDOOR_CATEGORY_ID,
    url: '/b-home-outdoor/c19',
    urlFr: '/b-exterieur-et-jardin/c19',
  },
  // JUN - Home - Outdoor & Garden
  {
    categoryId: CATEGORIES.HOME_OUTDOOR_CATEGORY_ID,
    url: '/b-home-outdoor/c19',
    urlFr: '/b-exterieur-et-jardin/c19',
  },
  // JUL - Motorcycles
  {
    categoryId: CATEGORIES.MOTORCYCLES_CATEGORY_ID,
    url: '/b-motorcycles/c30',
    urlFr: '/b-moto/c30',
  },
  // AGO - Motorcycles
  {
    categoryId: CATEGORIES.MOTORCYCLES_CATEGORY_ID,
    url: '/b-motorcycles/c30',
    urlFr: '/b-moto/c30',
  },
  // SEPT - Tires & Rims
  {
    categoryId: CATEGORIES.TIRES_RIMS_CATEGORY_ID,
    url: '/b-tires-rims/c320',
    urlFr: 'b-pneus-jantes/c320',
  },
  // OCT - Tires & Rims
  {
    categoryId: CATEGORIES.TIRES_RIMS_CATEGORY_ID,
    url: '/b-tires-rims/c320',
    urlFr: 'b-pneus-jantes/c320',
  },
  // NOV - Tires & Rims
  {
    categoryId: CATEGORIES.TIRES_RIMS_CATEGORY_ID,
    url: '/b-tires-rims/c320',
    urlFr: 'b-pneus-jantes/c320',
  },
  // DEC - Snowmobiles
  {
    categoryId: CATEGORIES.SNOWMOBILES_CATEGORY_ID,
    url: '/b-snowmobile/c313',
    urlFr: '/b-motoneige/c313',
  },
]
