import { CATEGORIES } from '@kijiji/category'
import dynamic from 'next/dynamic'
import { type ReactNode } from 'react'

const BuySellIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryBuyAndSell`), {
  ssr: false,
})
const CarsIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryCarsAndVehicles`), {
  ssr: false,
})
const CommunityIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryCommunity`), {
  ssr: false,
})
const JobsIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryJobs`), {
  ssr: false,
})
const PetsIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryPets`), {
  ssr: false,
})
const ServicesIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryServices`), {
  ssr: false,
})
const RealEstateIcon = dynamic(() => import(`@kijiji/icons/src/icons/IconCategoryRealEstate`), {
  ssr: false,
})
const VacationRentalsIcon = dynamic(
  () => import(`@kijiji/icons/src/icons/IconCategoryVacationRentals`),
  { ssr: false }
)

const CATEGORY_TO_ICON: { [categoryId: number]: ReactNode } = {
  [CATEGORIES.BUY_AND_SELL_CATEGORY_ID]: <BuySellIcon data-testid="buy-sell-icon" />,
  [CATEGORIES.CARS_AND_VEHICLES_CATEGORY_ID]: <CarsIcon data-testid="cars-icon" />,
  [CATEGORIES.COMMUNITY_CATEGORY_ID]: <CommunityIcon data-testid="community-icon" />,
  [CATEGORIES.JOBS_CATEGORY_ID]: <JobsIcon data-testid="jobs-icon" />,
  [CATEGORIES.PETS_CATEGORY_ID]: <PetsIcon data-testid="pets-icon" />,
  [CATEGORIES.REAL_ESTATE_CATEGORY_ID]: <RealEstateIcon data-testid="real-estate-icon" />,
  [CATEGORIES.SERVICES_CATEGORY_ID]: <ServicesIcon data-testid="services-icon" />,
  [CATEGORIES.VACATION_RENTALS_CATEGORY_ID]: <VacationRentalsIcon data-testid="vacation-icon" />,
}

export const getMenuCategoryIcon = (categoryId: number): ReactNode => {
  return CATEGORY_TO_ICON[categoryId]
}
