import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { ModalHeader } from '@/ui/molecules/modal/Modal'

export type LocationModalHeaderProps = {
  title: string
}

export const LocationModalHeader: FC<LocationModalHeaderProps> = ({ title }) => {
  const theme = useTheme()

  return (
    <ModalHeader>
      <HeadlineText color={theme.colors.purple.primary} size="small" textAlign="center">
        {title}
      </HeadlineText>
    </ModalHeader>
  )
}
