import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgNotification: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M19.964 17.315l-.304-.758A12.15 12.15 0 0118.414 11v-.582a6.332 6.332 0 00-5.43-6.338c.002-.028.016-.052.016-.08a1 1 0 00-2 0c0 .028.014.052.016.08a6.332 6.332 0 00-5.43 6.338V11a12.37 12.37 0 01-1.271 5.62l-.28.695A.5.5 0 004.5 18h3.674A4.283 4.283 0 0012 21a3.968 3.968 0 003.864-3H19.5a.5.5 0 00.464-.685zM12 20a3.246 3.246 0 01-2.76-2h5.578A2.889 2.889 0 0112 20zm3.5-3H5.24l.002-.006A13.294 13.294 0 006.585 11v-.582a5.414 5.414 0 1110.83 0V11a13.074 13.074 0 001.318 5.932l.027.068z"
      />
    </Icon>
  )
}
export default SvgNotification
