import { useEffect, useRef } from 'react'

/**
 * Runs the passed callback function once on mount
 */
export const useRunOnce = (callbackFunction: () => void) => {
  const triggered = useRef<boolean>(false)

  useEffect(() => {
    if (!triggered.current) {
      callbackFunction()
      triggered.current = true
    }
    /* We only want this effect to run once */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
