import styled from 'styled-components'

import { textLinkBaseStyle } from '@/ui/atoms/text-link/styled'

export const Container = styled.div(
  ({ theme }) => `
  font-size: 15px;

  & a {
    font-weight: ${theme.fontWeight.regular};
  }

  & button {
    font-weight: ${theme.fontWeight.regular};
  }
`
)

export const ConnectionWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
`

export const TextContainer = styled.span(
  ({ theme }) => `
  margin: 0 ${theme.spacing.mini};
`
)

export const LanguageSwitch = styled.span`
  ${({ theme }) =>
    textLinkBaseStyle(theme, {
      $hasUnderline: false,
      $size: 'medium',
      $variant: 'secondary',
      $weight: 'regular',
    })}
`
