import { theme, ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { Orientation } from '@/ui/typings/orientation'

import { TooltipProps } from './Tooltip'

type TooltipContainerProps = Pick<
  TooltipProps,
  'orientation' | 'variant' | 'isVisible' | 'zIndex'
>

type TooltipTriggerProps = {
  isVisible?: boolean
}

const renderOrientation = (orientation: Orientation) => {
  switch (orientation) {
    case 'left':
      return `
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 1.2rem);
        &::before {
          top: calc(50% - 0.75rem);
          left: -1.6rem;
          rotate: 270deg;
        }
      `
    case 'right':
      return `
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 1.2rem);
        left: unset;
        &::before {
          top: calc(50% - 0.75rem);
          right: -1.6rem;
          left: unset;
          rotate: 90deg;
        }
      `
    case 'top-left':
      return `
        top: calc(100% + 1.4rem);
        left: 0;
        &::before {
          top: -1.2rem;
          left: ${theme.spacing.mini};
        }
      `
    case 'top':
      return `
        top: calc(100% + 1.4rem);
        left: 50%;
        transform: translateX(-50%);
        &::before {
          top: -1.2rem;
          left: calc(50% - 0.75rem);
        }
      `
    case 'top-right':
      return `
        top: calc(100% + 1.4rem);
        right: 0;
        &::before {
          top: -1rem;
          right: ${theme.spacing.mini};
          left: unset;
        }
      `
    case 'bottom':
      return `
        bottom: calc(100% + 1.4rem);
        left: 50%;
        transform: translateX(-50%);
        top: unset;
        &::before {
          left: calc(50% - 0.75rem);
          right: unset;
          bottom: -1.2rem;
          top: unset;
          rotate: 180deg;
        }
      `
    case 'bottom-left':
      return `
        bottom: calc(100% + 1.4rem);
        left: 0;
        top: unset;
        &::before {
          left: ${theme.spacing.mini};
          right: unset;
          bottom: -1.2rem;
          top: unset;
          rotate: 180deg;
        }
      `
    case 'bottom-right':
      return `
        bottom: calc(100% + 1.4rem);
        left: unset;
        top: unset;
        right: 0;
        &::before {
          right: ${theme.spacing.mini};
          bottom: -1.2rem;
          top: unset;
          left: unset;
          rotate: 180deg;
        }
      `
  }
}

const colorVariation = (theme: ThemeProps) => ({
  dark: theme.colors.grey.dark1,
  light: theme.colors.white,
})

export const TooltipWrapper = styled.div`
  position: relative;
`

export const TooltipContainer = styled.div<TooltipContainerProps>(
  ({ variant = 'light', orientation, isVisible, zIndex, theme }) => `
  align-items: center;
  background-color: ${colorVariation(theme)[variant]};
  border-radius: ${theme.borderRadius.small};
  box-shadow: .2rem .2rem .4rem 0 ${theme.colors.grey.primary}1A;
  display: ${isVisible ? 'inline-flex' : 'none'};
  max-width: 33.9rem;
  width: max-content;
  padding: ${theme.spacing.defaultSmall};
  gap: ${theme.spacing.defaultSmall};
  position: absolute;
  user-select: none;
  z-index: ${zIndex || 1};

  &::before {
    top: -1rem;
    left: ${theme.spacing.mini};
  }

  ${renderOrientation(orientation)};

  p {
    color: ${
      variant === 'dark' ? theme.colors.white : theme.colors.grey.light1
    };
  }
  
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1.1rem 1.2rem 1.1rem;
    border-color: transparent transparent ${
      colorVariation(theme)[variant]
    } transparent;
  }
`
)

/* If the visibility of the tooltip is externally set, do not show the tooltip on hover */
export const TooltipTrigger = styled.div<TooltipTriggerProps>(
  ({ isVisible }) => `
  &[aria-describedby]:hover + [role='tooltip'],
  &[aria-describedby]:focus + [role='tooltip'] {
    display: ${isVisible === false ? 'none' : 'inline-flex'};
  }
  
  &[aria-describedby]:hover,
  &[aria-describedby]:focus {
    position: relative;
  }
`
)
export const TooltipIconContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.blue.primary};
  border-radius: ${theme.borderRadius.xLarge};
  display: flex;
  flex-direction: column;
  height: ${theme.spacing.large};
  justify-content: center;
  padding: ${theme.spacing.mini};
  width: ${theme.spacing.large};
  
  svg {
    color: ${theme.colors.white};
  }
`
)

export const TooltipCloseButton = styled.button(
  ({ theme }) => `
  background-color: transparent;
  cursor: pointer;
  height: ${theme.spacing.default};
  width: ${theme.spacing.default};
  align-self: flex-start;

  > svg {
    height: ${theme.spacing.default};
    width: ${theme.spacing.default};
  }

  &:hover {
    color: ${theme.colors.grey.primary};
  }
`
)
