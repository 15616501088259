import { type SearchCategory, useGetSearchCategoryQuery } from '@kijiji/generated/graphql-types'
import { useCallback, useEffect } from 'react'

import { DEFAULT_CATEGORY } from '@/constants/category'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { useGetSearchResultsData } from '@/hooks/srp'
import { updateSearchCategoryCache } from '@/utils/category/updateSearchCategoryCache'

export const useGetSearchCategory = (): {
  category: SearchCategory
  updateSearchCategory: (newCategory?: SearchCategory) => void
} => {
  const { data, client } = useGetSearchCategoryQuery()
  const { data: searchData } = useGetSearchResultsData({ fetchPolicy: 'cache-only' })

  const category = data?.srp.searchCategory ?? DEFAULT_CATEGORY

  useEffect(() => {
    const searchCategory = searchData?.searchQuery.category

    if (searchCategory && searchCategory.id === category.id) return

    const updatedCategory = getSearchCategoryFromSearchQuery(searchCategory)
    updateSearchCategory(updatedCategory)

    // This hook should only update the category when the searchQuery is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData?.searchQuery.category])

  const updateSearchCategory = useCallback(
    (newCategory?: SearchCategory) => {
      if (!newCategory) {
        updateSearchCategoryCache(DEFAULT_CATEGORY, client)
        return
      }

      updateSearchCategoryCache(newCategory, client)
    },
    [client]
  )

  return { category, updateSearchCategory }
}
