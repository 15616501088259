import { useGetLocationFromPlaceLazyQuery } from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

import { useGooglePlaceToken } from '@/components/shared/search-location-modal/useGooglePlaceToken'
import { CookieRegistry } from '@/constants/cookieRegistry'
import { getLocationFromPlaceResult } from '@/domain/location/getLocationFromPlaceResult'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * The returned function fetches the google place token from the cookies
 * if present, otherwise requests it from Anvil.
 * Uses the google place token to fetch the location from the place ID.
 * Parses the returned location into a Location object to be used by the location modal.
 * @returns Function that fetches location given its place ID
 */
export const useFetchLocationFromPlaceId = () => {
  const { getGooglePlaceToken } = useGooglePlaceToken()
  const [getLocationFromPlace] = useGetLocationFromPlaceLazyQuery()

  /**
   * Function to fetch location from place ID
   * @param placeId Place ID of location
   * @param address Address of location
   * @returns Location object
   */
  const fetchLocationFromPlaceId = useCallback(
    async ({ placeId, address }: { placeId: string; address: string }) => {
      try {
        const googlePlaceToken = await getGooglePlaceToken()
        const { data, error } = await getLocationFromPlace({
          variables: { placeId },
          context: {
            headers: { [CookieRegistry.GOOGLE_PLACE_TOKEN]: googlePlaceToken },
          },
        })
        if (error || !data?.locationFromPlace?.place) {
          const errorMessage = error?.message || 'unexpected `locationFromPlace` data'
          sendToLogger(errorMessage, {
            tags: { fn: 'fetchLocationFromPlaceId', component: 'useFetchLocationFromPlaceId' },
            fingerprint: ['useFetchLocationFromPlaceId', 'missingData'],
          })
          return
        }
        return getLocationFromPlaceResult(data?.locationFromPlace, address)
      } catch (error) {
        sendToLogger('Unknown error occurred', {
          tags: { fn: 'fetchLocationFromPlaceId', component: 'useFetchLocationFromPlaceId' },
          fingerprint: ['useFetchLocationFromPlaceId', 'catch'],
        })
      }
    },
    [getLocationFromPlace, getGooglePlaceToken]
  )

  return { fetchLocationFromPlaceId }
}
