import styled from 'styled-components'

import { SEARCH_BAR_SECOND_LINE_BREAK } from '@/constants/others'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type ToolsContainerProps = {
  isHeaderSimplified?: boolean
}

export const ToolsContainer = styled.div<ToolsContainerProps>(
  ({ theme, isHeaderSimplified }) => `
  align-items: center;
  display: flex;
  margin-left: ${isHeaderSimplified ? '0' : theme.spacing.default};
  order: 2;
  white-space: nowrap;
  gap: ${theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).medium} {
    gap: ${theme.spacing.default};
  }

  ${SEARCH_BAR_SECOND_LINE_BREAK} {
    flex-grow: 1;
    justify-content: flex-end;
    margin-left: unset;
    order: ${isHeaderSimplified ? 2 : 3};
    margin-top: ${theme.spacing.mini};
  }
`
)

export const HiddenMobile = styled.span(
  ({ theme }) => `
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    display: contents;
  }
`
)
