import { type UserLocation } from '@kijiji/generated/graphql-types'
import qs from 'query-string'

/**
 * Attach radial location to URL query parameters
 */
export const attachRadialLocationParams = (href: string, location: UserLocation) => {
  /** It should only append radial parameters if it is not a region location */
  if (!href || location.isRegion || !location.area) return href

  const { url, query } = qs.parseUrl(href)

  /** Check if radial query parameters are already present in the URL */
  if (query.ll && query.address && query.radius) {
    return href
  }

  /** Append parameters to the URL */
  query.address = location.area.address || ''
  query.ll = `${location.area.latitude},${location.area?.longitude}`
  query.radius = `${location.area.radius}`

  return qs.stringifyUrl({ url, query })
}
