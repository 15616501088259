import { useTranslation } from 'next-i18next'
import {
  type ForwardedRef,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  forwardRef,
  useRef,
} from 'react'

import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { attachRadialLocationParams } from '@/domain/location/attachRadialLocationParams'
import { getMenuCategoryIcon } from '@/features/navigation/api/getMenuCategoryIcon'
import { MegaMenuMoreEllipsis } from '@/features/navigation/components/MegaMenuMoreEllipsis'
import {
  megaMenuCustomSlideStyle,
  MegaMenuL1Container,
  MegaMenuL1IconsListContainer,
  MegaMenuL1Link,
  MegaMenuL1List,
  MegaMenuL1ListItem,
  MegaMenuL1Nav,
  MegaMenuMobileL1IconContainer,
} from '@/features/navigation/components/styled'
import { TrendingCategory } from '@/features/navigation/components/TrendingCategory'
import { type ActiveMegaMenuReturn } from '@/features/navigation/hooks/useActiveMegaMenu'
import { useGetLocation } from '@/hooks/location/useGetLocation'

export type MegaMenuInlineCategoriesProps = Pick<
  ActiveMegaMenuReturn,
  | 'closeMenu'
  | 'handleClearTimeout'
  | 'isCollapsedMenuView'
  | 'l1MenuItems'
  | 'menuBarRef'
  | 'onHover'
  | 'openMenu'
  | 'selectedL1Item'
  | 'icons'
> &
  PropsWithChildren

export const MegaMenuL1 = forwardRef(function MegaMenuL1(
  {
    children,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    l1MenuItems,
    menuBarRef,
    onHover,
    openMenu,
    selectedL1Item,
    icons = true,
  }: MegaMenuInlineCategoriesProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const { location } = useGetLocation()
  const { t } = useTranslation('global_header')
  const megaMenuL1ContainerRef = useRef<HTMLDivElement>(null)

  const handleL1Click = (e: MouseEvent<HTMLAnchorElement>, categoryId: number) => {
    /**
     * Prevent L1 link from redirecting user to SRP on click
     * While still keeping it a link for SEO purposes
     * */
    e.preventDefault()

    openMenu(categoryId, 1, true)
  }

  if (!l1MenuItems?.length) return null

  const handleCloseMenu = () => closeMenu(1)

  /** Get 2 last items of the array to add to "more" ellipsis menu */
  const moreMenuItems = l1MenuItems.slice(-2)

  const onKeyDown = (event: KeyboardEvent<HTMLLIElement>, categoryId: number) => {
    switch (event.key) {
      case 'Enter':
      case ' ': {
        event.preventDefault()
        openMenu(categoryId, 1, true, true)
        break
      }
    }
  }

  const onChildKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    switch (event.key) {
      case 'Escape': {
        const nextElementSibling = megaMenuL1ContainerRef.current?.nextElementSibling
        // set focus to the next element in the DOM (i.e. Trending Category)
        if (nextElementSibling instanceof HTMLElement) nextElementSibling?.focus()
        break
      }
    }
  }

  const listItems = l1MenuItems.map((item) => {
    if (!item) return null

    const icon = getMenuCategoryIcon(item.id)
    const handleOpenMenu = () => openMenu(item.id, 1)
    const handleHover = () => onHover(item.id, 1)
    const seoUrl = attachRadialLocationParams(item.seoUrl, location)

    return (
      <MegaMenuL1ListItem
        isActive={item.id === selectedL1Item?.id}
        key={`${item.categoryName}-${item.id}`}
        onBlur={handleClearTimeout}
        onMouseEnter={handleOpenMenu}
        onMouseMove={handleHover}
        onMouseOut={handleClearTimeout}
        onKeyDown={(e) => onKeyDown(e, item.id)}
        role="menuitem"
      >
        <MegaMenuL1Link
          aria-controls={`cat-menu-group-${item.id}`}
          aria-expanded={selectedL1Item?.id === item.id}
          aria-haspopup="true"
          href={seoUrl}
          onClick={(e) => handleL1Click(e, item.id)}
          ref={selectedL1Item?.id === item.id ? ref : null}
        >
          {icons && <MegaMenuMobileL1IconContainer>{icon}</MegaMenuMobileL1IconContainer>}
          <span>{item.categoryName}</span>
        </MegaMenuL1Link>
      </MegaMenuL1ListItem>
    )
  })

  const handleMouseLeave = () => {
    if (isCollapsedMenuView) return

    closeMenu(2)
  }

  return (
    <MegaMenuL1Nav aria-label={t('mega_menu.nav.label')} onMouseLeave={handleMouseLeave}>
      <MegaMenuL1Container onMouseLeave={handleCloseMenu} tabIndex={0} ref={megaMenuL1ContainerRef}>
        <MegaMenuL1IconsListContainer>
          <LegacyCarousel
            listCustomProps={{
              'aria-label': t('mega_menu.nav.label'),
              role: 'menubar',
              ref: menuBarRef,
            }}
            dragFree
            name="hp-similar-to-favourites-carousel"
            preventNestedListItem
            removeHorizontalSpacing
            shouldShowArrows={[true, false, false]}
            slideCustomStyle={megaMenuCustomSlideStyle}
            slides={listItems}
            slidesToShow={[8, 8, 3.5]}
            useLegacyLgDesktopBreakpoint
          />
        </MegaMenuL1IconsListContainer>

        <MegaMenuL1List role="menubar">
          {listItems}
          <MegaMenuMoreEllipsis
            moreMenuItems={moreMenuItems}
            parentOnKeyDownHandler={onChildKeyDown}
          />
        </MegaMenuL1List>

        {/* Submenu L2/L3 */}
        {children}
      </MegaMenuL1Container>

      <TrendingCategory />
    </MegaMenuL1Nav>
  )
})
