import { useGetUserAuthStateQuery } from '@kijiji/generated/graphql-types'
import { useSession as nextAuthUseSession } from 'next-auth/react'

import { isUserAuthenticated, isUserSessionLoading } from '@/features/auth/constants/user'

/**
 * Custom hook to get the user session.
 * It combines the session from next-auth and our own
 * custom auth logic, to return them both.
 *
 * Ideally, we should expose booleans like `isAuthenticated`,
 * `isUserLoading`, `isUnauthenticated`, `optimisticIsAuthenticated`
 * and remove next-auth's own `status` string from the return.
 */
export const useSession = () => {
  const nextAuthUseSessionReturn = nextAuthUseSession()

  const { data } = useGetUserAuthStateQuery()
  const isServerAuthenticated = !!data?.userAuthState.isMiddlewareAuthenticated
  const optimisticIsAuthenticated = isUserSessionLoading(nextAuthUseSessionReturn.status)
    ? isServerAuthenticated
    : isUserAuthenticated(nextAuthUseSessionReturn.status)

  return { ...nextAuthUseSessionReturn, optimisticIsAuthenticated }
}
