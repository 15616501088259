import type { ColorPallet } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { SystemMessageProps } from './SystemMessage'

type SystemMessageContainerProps = {
  descriptionList: boolean
  $variation: SystemMessageProps['variation']
}

type SystemMessageCloseButtonProps = {
  description: boolean
}

export const colorByVariation: {
  [x in SystemMessageProps['variation']]: keyof ColorPallet
} = {
  error: 'red',
  warning: 'yellow',
  success: 'green',
  tip: 'blue',
}

export const SystemMessageContainer = styled.div<SystemMessageContainerProps>(
  ({ theme, descriptionList, $variation }) => {
    const variationColor = theme.colors[colorByVariation[$variation]]

    return `
      align-items: center;
      background-color: ${variationColor.light5};
      border-radius: ${theme.spacing.mini};
      border: 1px solid ${variationColor.light4};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 140.8rem;
      padding: ${theme.spacing.default};
      position: relative;  
      width: 100%;

      ${SystemMessageCloseButton} {
        ${descriptionList ? `align-self: start;` : ''}
        margin-left: auto;
        padding-left: ${theme.spacing.default};
      }
      
       .system-message-icon {
        svg {
          flex-shrink: 0;
          ${$variation === 'error' ? `color: ${theme.colors.red.primary}` : ''}
        }
      }

      // Only add margin if the <p> has a sibling
       p + ul,
       p + ol {
        margin-top: ${theme.spacing.defaultSmall};
      }

      ${MEDIA_QUERIES(theme).medium}{
        flex-direction: row;
        min-width: 32.8rem;
        padding: ${theme.spacing.default};

        .system-message-icon {
          ${descriptionList ? `align-self: start;` : ''}
          margin-right: ${theme.spacing.default};
        }        
      }
    `
  }
)

export const SystemMessageTextContainer = styled.div(
  ({ theme }) => `
  ul,
  ol {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: start;
  }

  p {
    text-align: center;
  }

  ${MEDIA_QUERIES(theme).medium}{
    p {
      text-align: start;
    }
    
    ul,
    ol {
      align-items: flex-start;
      text-align: start;
    }
  }
`
)

export const SystemMessageCloseButton =
  styled.button<SystemMessageCloseButtonProps>(
    ({ theme, description }) => `
  color: ${theme.colors.grey.primary};
  cursor: pointer;
  position: absolute;
  right: ${theme.spacing.default};
  top: ${theme.spacing.default};

  svg {
    height: ${theme.spacing.large};
    width: auto;
  }

  ${MEDIA_QUERIES(theme).medium}{
    position: relative;
    right: 0;
    top: 0;

    svg {
      height: ${description ? theme.spacing.xLarge : theme.spacing.large};
      width: auto;
    }
  }
`
  )
