import { type GetCampaignsQuery } from '@kijiji/generated/graphql-types'

import { type CampaignPlatform } from '@/types/customCampaigns'
import { getUserDevice } from '@/utils/getUserDevice'
import { sendToLogger } from '@/utils/sendToLogger'

/**
 * Defines which device is making the campaign request
 * This will modify which campaigns will be returned to the user
 */
export const getCustomCampaignPlatform = (): CampaignPlatform => {
  const { isPhone, isTablet } = getUserDevice()
  if (isPhone) return 'mweb'

  if (isTablet) return 'tablet'

  return 'desktop'
}

const parseCampaignCustomFields = (customFields: string) => {
  try {
    const parsedItem = JSON.parse(customFields)
    return parsedItem
  } catch (e) {
    sendToLogger(e, {
      tags: { fn: 'parseCampaignCustomFields' },
      fingerprint: ['JsonParse'],
      extra: { value: `custom-fields: ${customFields}` },
    })

    return {}
  }
}

/**
 * Campaigns are returned in a JSON string format
 * This function is responsible for parsing the campaigns into an array and limit the response if requested
 */
export const parseCampaigns = <CampaignResponse>({
  campaigns,
  filterAmount = 0,
}: {
  campaigns: GetCampaignsQuery['campaigns']
  filterAmount?: number
}) => {
  if (!campaigns?.length) return []

  const formattedCampaigns = campaigns.reduce((acc: CampaignResponse[], curr) => {
    if (!curr?.customFields || !curr.id) return acc
    const parsedItem = parseCampaignCustomFields(curr.customFields)

    return [...acc, { ...parsedItem, id: curr.id }]
  }, [])

  /**
   * The parsed data will depend on the campaign type
   */
  return filterAmount === 1 ? [formattedCampaigns[0]] : formattedCampaigns
}
