import { type NullableFields } from '@/utils/types'

/**
 * Polyfill of Object.hasOwn function for older browser versions
 */
export const objectHasOwnPolyfill = (obj: object, prop: PropertyKey): boolean => {
  if (!Object.hasOwn) {
    return Object.prototype.hasOwnProperty.call(obj, prop)
  }

  return Object.hasOwn(obj, prop)
}

/**
 * Checks whether the passed variable is an object (arrays and functions are not considered to be object in this case)
 * @param valueToCheck - Any variable you would like to determine is or is not an object.
 * @returns A boolean that is true if the passed variable is an object and false if it is not an object.
 */
export const isNonNullableObject = (
  valueToCheck: unknown
): valueToCheck is Record<string, unknown> => {
  return (
    typeof valueToCheck === 'object' &&
    valueToCheck !== null &&
    !Array.isArray(valueToCheck) &&
    typeof valueToCheck !== 'function'
  )
}

/**
 * Recursively loops through an object recording all keys that return undefined values.
 * @param object - Object you would like to filter for undefined values.
 * @returns An array with the object keys that returned undefined.
 */
export const getUndefinedPropertyKeys = (object: Record<string, unknown>): Promise<string[]> => {
  return new Promise((resolve) => {
    const undefinedProperties: string[] = []

    const recordUndefinedValues = (object: Record<string, unknown>) => {
      if (!isNonNullableObject(object)) return []
      const objectKeys = Object.keys(object)
      if (objectKeys.length === 0) return []

      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const objectProperty = object[key]

          if (objectProperty === undefined) {
            undefinedProperties.push(key)
          } else if (Array.isArray(objectProperty)) {
            const undefinedPropertiesSubArray = objectProperty.reduce((accumulator, item) => {
              return accumulator.concat(recordUndefinedValues(item))
            }, [])
            undefinedProperties.concat(undefinedPropertiesSubArray)
          } else if (isNonNullableObject(objectProperty)) {
            undefinedProperties.concat(recordUndefinedValues(objectProperty))
          }
        }
      }

      return []
    }

    recordUndefinedValues(object)

    resolve(undefinedProperties)
  })
}

export class UndefinedValueError extends Error {
  undefinedProperties: string[]

  constructor(message: string, undefinedPropertiesArray: string[]) {
    super(message)

    this.name = 'UndefinedValueError'

    this.undefinedProperties = undefinedPropertiesArray
  }
}

/**
 * Removes undefined values from an object.
 * @param obj - The object to clean.
 * @returns A new object with undefined values removed.
 */
export const cleanObjectOfUndefinedValues = <T extends Record<string, unknown>>(obj: T): T => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key as keyof T] = value as T[keyof T] // Explicit cast to ensure compatibility
    }
    return acc
  }, {} as T)
}

/**
 * Replaces undefined values with null in an object. Does not work with nested objects.
 * @param obj - The object to modify.
 * @returns A new object with undefined values replaced with null.
 */
export const replaceUndefinedWithNull = <T extends { [key: string]: unknown }>(
  obj: T
): NullableFields<T> => {
  const result: { [key: string]: unknown } = {}

  Object.entries(obj).forEach(([key, value]) => {
    result[key] = value === undefined ? null : value
  })

  return result as NullableFields<T>
}
