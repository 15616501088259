export const zIndexRegistry = {
  ROOT: 0,
  CARD: 5,
  CARD_ELEMENTS: 10,
  DROPDOWN: 202,
  SEARCH_BAR: 1,
  SRP_SORT_DROPDOWN: 125,
  TOP_LEADERBOARD: 150,
  MAIN_MENU: 200,
  MODAL: 300,
  USER_FEEDBACK_TOAST: 301,
  LOCATION_MODAL_SEARCH_BAR: 305,
  FEATURED_LISTING_BADGE: 1,
  FLOATING_CTA: 299,
  MWEB_STICKY_AD: 299,
  CAROUSEL_ARROWS: 2,
  AUTOCAN_DROPDOWN: 11,
}
