import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { type LegacyCarouselProps } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { type UseLegacyLgDesktopBreakpoint } from '@/types/common'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const LegacyCarouselContainer = styled.div`
  position: relative;
  width: 100%;
`

export const LegacyCarouselViewport = styled.div`
  overflow: hidden;
`

type ListProps = UseLegacyLgDesktopBreakpoint & { removeHorizontalSpacing?: boolean }

export const List = styled.ul<ListProps>(
  ({ theme, useLegacyLgDesktopBreakpoint, removeHorizontalSpacing = false }) => `
  display: flex;
  flex-direction: row;
  height: auto;
  gap: ${theme.spacing.defaultSmall};
  transition: transform .5s;
  ${!removeHorizontalSpacing ? `padding: ${theme.spacing.mini} ${theme.spacing.default};` : ''}

  ${MEDIA_QUERIES(theme).medium}{
    ${!removeHorizontalSpacing ? `padding: 0 ${theme.spacing.large} 0 ${theme.spacing.husky};` : ''}
  }

  ${
    useLegacyLgDesktopBreakpoint
      ? `${MEDIA_QUERY_HP_LARGE_DESKTOP}`
      : `${MEDIA_QUERIES(theme).large}`
  }{
    padding: 0;
  }
`
)

type SlideProps = UseLegacyLgDesktopBreakpoint & {
  slidesToShow?: [number, number, number]
  slideCustomStyle?: string
}

const getSlideWidth = (slidesToShow?: number) =>
  !slidesToShow ? 'unset' : `calc(${(100 / slidesToShow).toFixed(2)}% - 0.7rem)`

export const Slide = styled.li<SlideProps>(
  ({ theme, slidesToShow, slideCustomStyle, useLegacyLgDesktopBreakpoint }) => `
  flex: 0 0 auto;
  width: ${getSlideWidth(slidesToShow?.[2])};

  ${MEDIA_QUERIES(theme).medium}{
    width: ${getSlideWidth(slidesToShow?.[1])};
  }

  ${
    useLegacyLgDesktopBreakpoint
      ? `${MEDIA_QUERY_HP_LARGE_DESKTOP}`
      : `${MEDIA_QUERIES(theme).large}`
  } {
    width: ${getSlideWidth(slidesToShow?.[0])};
  }

  ${slideCustomStyle ?? ''}
`
)

export const CarouselDots = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.defaultSmall} 0;
  `
)

type PrevNextBtnWrapperProps = UseLegacyLgDesktopBreakpoint & {
  shouldShowArrows: LegacyCarouselProps['shouldShowArrows']
}

const showArrowPerBreakpointIndex = (
  shouldShowArrows: PrevNextBtnWrapperProps['shouldShowArrows'],
  index: number
) => {
  /** Definition for all breakpoints */
  const isTrueBoolean = typeof shouldShowArrows === 'boolean'
  return isTrueBoolean ? shouldShowArrows === true : shouldShowArrows?.[index]
}

export const PrevNextBtnWrapper = styled.div<PrevNextBtnWrapperProps>(
  ({ theme, shouldShowArrows, useLegacyLgDesktopBreakpoint }) => {
    return ` display: ${showArrowPerBreakpointIndex(shouldShowArrows, 2) ? 'block' : 'none'};

    ${MEDIA_QUERIES(theme).medium} {
      display: ${showArrowPerBreakpointIndex(shouldShowArrows, 1) ? 'block' : 'none'};
    }

    ${
      useLegacyLgDesktopBreakpoint
        ? `${MEDIA_QUERY_HP_LARGE_DESKTOP}`
        : `${MEDIA_QUERIES(theme).large}`
    }  {
      display: ${showArrowPerBreakpointIndex(shouldShowArrows, 0) ? 'block' : 'none'};
    }

  `
  }
)
