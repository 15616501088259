import {
  type GetSearchResultsPageByUrlQuery,
  type SearchCategory,
  type SearchCategoryPath,
} from '@kijiji/generated/graphql-types'

import { ALL_CATEGORIES_ID_NUM, DEFAULT_CATEGORY } from '@/constants/category'

export const getSearchCategoryFromSearchQuery = (
  searchQueryCategory: GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['searchQuery']['category'],
  isDomCat?: boolean
): SearchCategory => {
  // All categories has the old name "Classifieds", so we return our default category
  // Should review and update naming in database
  if (!searchQueryCategory || searchQueryCategory.id === ALL_CATEGORIES_ID_NUM) {
    return DEFAULT_CATEGORY
  }

  const categoryPath =
    searchQueryCategory.categoryPaths?.reduce((acc: SearchCategoryPath[], curr) => {
      if (!curr?.id) return acc

      const path: SearchCategoryPath = {
        id: curr.id,
        localizedName: curr.localizedName ?? '',
        parentId: curr.parentId ?? null,
        seoUrl: curr.searchSeoUrl ?? null,
      }

      return [...acc, path]
    }, []) || []

  const category: SearchCategory = {
    id: searchQueryCategory.id,
    isDomCat: !!isDomCat,
    localizedName: `${searchQueryCategory.localizedName}`,
    name: null,
    parentId: searchQueryCategory.parentId ?? null,
    path: categoryPath,
    seoUrl: searchQueryCategory.searchSeoUrl,
  }

  return category
}
