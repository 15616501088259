import { theme } from '@kijiji/theme'
import { FC, HTMLAttributes } from 'react'

import { BodyText } from '@/ui/atoms/body-text'

export type HelperTextProps = HTMLAttributes<HTMLParagraphElement> & {
  /**
   * Id value passed on from the parent component. Links helper text with its associated input. Ensures all relevant information associated with form inputs is accessible.
   */
  id: string
}

/**
 * @description Small text that appears below input fields intended to provide users with instructions or additional context
 */

export const HelperText: FC<HelperTextProps> = ({
  children,
  id,
  ...rest
}: HelperTextProps) => {
  return (
    <BodyText
      color={theme.colors.grey.light1}
      data-testid={`helper-text-${id}`}
      id={`${id}-helper-text`}
      size="small"
      {...rest}
    >
      {children}
    </BodyText>
  )
}
