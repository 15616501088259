import ErrorIcon from '@kijiji/icons/src/icons/Error'
import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

export const FormErrorText = styled(BodyText)`
  align-items: flex-start;
  display: flex;
`

export const FormErrorIcon = styled(ErrorIcon)(
  ({ theme }) => `
  flex-shrink: 0;
  height: auto;
  margin-right: ${theme.spacing.defaultSmall};
  width: 2rem;
`
)
