/**
 * Legacy messages behave similar to the new system messages, but they use a different key
 * format and are only used by our legacy systems. This is ported from FES.
 *
 * Ideally once we have transitioned away from the legacy systems, we should remove this file
 * and the related translation keys.
 */

import { type SystemMessageProps } from '@/ui/molecules/system-message'

type MessageType = Pick<SystemMessageProps, 'variation'>
type LegacyMessageTypeKeys = 'success' | 'error' | 'info' | 'promo' | 'reply'

const LEGACY_MESSAGE_TYPE_MAP: Record<LegacyMessageTypeKeys, MessageType['variation']> = {
  success: 'success',
  error: 'error',
  info: 'tip',
  promo: 'warning',
  reply: 'warning',
}

export type LegacyMessage = {
  key: string
  titleKey: string
  descriptionKey?: string
} & MessageType

const isLegacyMessageTypeKey = (key: string): key is LegacyMessageTypeKeys =>
  key in LEGACY_MESSAGE_TYPE_MAP

const isKeysWithLinks = (key: string): key is keyof typeof KEYS_WITH_LINKS => key in KEYS_WITH_LINKS

export const getLegacyMessage = (messageKey: string, messageClass: string) => {
  messageKey = messageKey.replace(/\./g, '_')
  if (LEGACY_MESSAGE_KEYS.has(messageKey) && isLegacyMessageTypeKey(messageClass)) {
    return {
      key: messageKey,
      titleKey: '',
      descriptionKey: `legacy_messages.${messageKey}`,
      variation: LEGACY_MESSAGE_TYPE_MAP[messageClass],
      links: isKeysWithLinks(messageKey) ? [`legacy_messages.${KEYS_WITH_LINKS[messageKey]}`] : [],
    }
  }

  return null
}

export const LEGACY_MESSAGE_KEYS = new Set([
  'homepage_logout_message',
  'homepage_login_message',
  'my_ad_mail_management_delete_success',
  'my_ad_mail_management_validation_activation_badTransition',
  'my_ad_mail_management_validation_common_adExpired',
  'my_ad_mail_management_validation_common_userNotFound',
  'my_ad_mail_management_validation_delete_badTransition',
  'my_ad_mail_management_validation_delete_badTransitionDeleted',
  'my_ad_mail_management_validation_extend_notActivatedYet',
  'my_ad_mail_management_validation_update_badTransition',
  'my_ad_mail_management_validation_update_badTransitionDeleted',
  'my_feature_shoppingCart_Failed',
  'my_feature_shoppingCart_Unavailable',
  'my_password_change_success_message',
  'my_password_reset_mailsent_message',
  'my_password_reset_success_message',
  'my_promoteAds_show_receipt_link',
  'my_registration_success',
  'optout_already',
  'optout_failure',
  'optout_success',
  'otherAds_userHasNoAds',
  'postad_editing_blocked_header',
  'postad_failed_contact_cs',
  'postad_pro_cancel_ad',
  'postAdForm_adLimit_warning_overCap_repost_admarkt',
  'search_emailalert_deleted_all_success_message',
  'search_emailalert_missing_guid',
  'search_emailalert_not_found',
  'user_activation_success',
  'viewad_message_ad_repost_deleted',
  'viewad_message_ad_repost_forbidden',
  'viewad_message_ad_repost_invalid',
])

const KEYS_WITH_LINKS = {
  my_ad_mail_management_validation_delete_badTransitionDeleted: 'post_link',
  my_ad_mail_management_validation_update_badTransitionDeleted: 'post_link',
  postAdForm_adLimit_warning_overCap_repost_admarkt: 'ad_limit_link',
  viewad_message_ad_repost_deleted: 'post_link',
  postad_failed_contact_cs: 'cs_link',
}
