import { useRouter } from 'next/router'

import { useLegacySafePush } from '@/hooks/useLegacySafePush'

/**
 * This hook is used for the menu navigation to allow shallow routing when on the Srp page.
 *
 * @returns {Object} An object with a navigateToPage function.
 */
export function useMenuNavigation() {
  const router = useRouter()
  const legacySafePush = useLegacySafePush()

  const navigateToPage = (url: string) => {
    const isSrpPage = router.pathname === '/srp/[...categoryOrLocation]'

    if (isSrpPage) {
      router.push(url, undefined, { shallow: true })
    } else {
      legacySafePush(url)
    }
  }

  return { navigateToPage }
}
