import Recent from '@kijiji/icons/src/icons/Recent'
import { colors } from '@kijiji/theme'

import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'
import { LOCATION_OPTION_TYPE } from '@/constants/location'

export const normalizeRecentLocationSuggestions = (
  updatedRecentSearchLocations: RecentSearchLocation[] | []
) => {
  return updatedRecentSearchLocations.map((location, index) => {
    return {
      type: LOCATION_OPTION_TYPE.RECENT_SEARCH_SUGGESTIONS,
      address: location.address || location.internalName || '',
      suggestionId: index.toString(10),
      // in order to align these icons correctly with the icons with a background, we need to pass a transparent background
      icon: <Recent aria-hidden backgroundColor="transparent" color={colors.grey.primary} />,
    }
  }, [])
}
