import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSuccess: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M19.665 3c9.204 0 16.665 7.46 16.665 16.665S28.87 36.33 19.665 36.33 3 28.87 3 19.665 10.46 3 19.665 3z"
        fill="#56c17c"
        fillRule="evenodd"
      />
      <path
        d="M12 20l5.293 5.293a1 1 0 001.414 0L28 16"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
      />
    </Icon>
  )
}
export default SvgSuccess
