import { ThemeProps } from '@kijiji/theme'
import { forwardRef, HTMLAttributes } from 'react'

import { Direction } from '@/ui/typings/direction'

import { ListBase } from './styled'

export type ListProps = HTMLAttributes<HTMLUListElement> & {
  /**
   * The type of element you want text to be rendered in
   * @default "ul"
   */
  as?: 'ol' | 'ul'
  /**
   * Controls whether a border separates each item
   * @default false
   */
  hasDivider?: boolean
  /**
   * Size of;
   *   - primaryText (children) with no secondaryText
   *   - secondaryText if specified
   * @default "medium"
   */
  fontSize?: keyof ThemeProps['typography']['body']
  /**
   * The orientation of the list
   * @default "vertical"
   */
  variant?: Direction
  /**
   * The amount of spacing to be provided between list items
   * If there is a divider, the spacing on left and right will
   * be half that of the given space.
   */
  spacing?: string
}

export const List = forwardRef<HTMLUListElement, ListProps>(
  (
    {
      as = 'ul',
      hasDivider = false,
      variant = Direction.vertical,
      fontSize = 'medium',
      spacing = '1.2rem',
      ...rest
    }: ListProps,
    forwardedRef
  ) => {
    return (
      <ListBase
        $hasDivider={hasDivider}
        $fontSize={fontSize}
        $variant={variant}
        $spacing={spacing}
        as={as}
        ref={forwardedRef}
        {...rest}
      />
    )
  }
)
