import { type RouteLocale, ROUTE_LOCALE } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

export const trackGlobalHeaderLanguageSwitch = (routeLocale?: RouteLocale) => {
  trackEvent({
    action: GA_EVENT.SwitchLanguage,
    label: `${GA_CTA_HEADER};dir="${routeLocale === ROUTE_LOCALE.fr ? 'fr_to_en' : 'en_to_fr'}"`,
  })
}
