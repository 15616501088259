import { type Filter, type SearchControls } from '@kijiji/generated/graphql-types'

import { isAttributeFilter, isRangeFilter } from '@/types/search'

export type ParentFilter = { isSelected: boolean; label: string }
/**
 * Checks if a filter is dependent on a parent one
 * If it has a dependent parent filter - it should check if it is selected and retrieve the label
 */
export const getParentFilter = (
  childFilter: Filter,
  filters: SearchControls['filtering']
): ParentFilter | undefined => {
  if (!childFilter.parentName) return

  const parentFilter = filters.find((filter) => filter.filters[0].name === childFilter.parentName)

  if (!parentFilter) return

  const parent = parentFilter.filters[0]

  if (isAttributeFilter(parent)) {
    return { isSelected: !!parent.selectedValues, label: parent.label.toLowerCase() }
  }

  if (isRangeFilter(parent)) {
    return { isSelected: !!parent.selectedRangeValues, label: parent.label.toLowerCase() }
  }

  return
}
