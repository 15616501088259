import { type ThemeProps } from '@kijiji/theme'

export const PROFILE_PHONE_PLACEHOLDER = '+1-XXX-XXX-XXXX'

/** AVATAR DEFINITIONS */
type AvatarVariations = 'PROFILE_OVERVIEW' | 'GLOBAL_HEADER'
export const AVATAR_VARIATIONS: {
  [x in AvatarVariations]: {
    iconSize: string
    labelSize: keyof ThemeProps['typography']['body']
    size: string
  }
} = {
  GLOBAL_HEADER: { iconSize: '2.4rem', size: '3.2rem', labelSize: 'small' },
  PROFILE_OVERVIEW: { iconSize: '4rem', size: '6rem', labelSize: 'xLarge' },
}
