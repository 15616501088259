import { useGetSearchKeywordQuery } from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

import { updateSearchKeywordCache } from '@/utils/keyword/updateSearchKeywordCache'

export const useGetSearchKeyword = (): {
  keyword: string
  updateSearchKeyword: (newKeyword: string) => void
} => {
  const { data, client } = useGetSearchKeywordQuery()

  const keyword = data?.srp.searchKeyword || ''

  const updateSearchKeyword = useCallback(
    (newKeyword?: string) => {
      /** Update Cache */
      updateSearchKeywordCache(newKeyword, client)
    },
    [client]
  )

  return { keyword, updateSearchKeyword }
}
