import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { TRENDING_CATEGORIES } from '@/constants/trendingCategories'
import { ROUTE_LOCALE } from '@/domain/locale'
import {
  TrendingCategoriesBlock,
  TrendingCategoriesLink,
} from '@/features/navigation/components/styled'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { type GaEvent } from '@/lib/ga/constants/gaEvent'

export const TrendingCategory = () => {
  const { routeLocale } = useLocale()
  const { t } = useTranslation('global_header')

  const getTrendingCategory = () => {
    const today = new Date()

    /**
     * Returns the month number starting at 0 (i.e. January is month 0)
     * */
    const month = today.getMonth()

    return TRENDING_CATEGORIES[month]
  }

  const trendingCategory = useMemo(() => getTrendingCategory(), [])
  const trendingLink = useMemo(
    () => (routeLocale === ROUTE_LOCALE.fr ? trendingCategory.urlFr : trendingCategory.url),
    [routeLocale, trendingCategory.url, trendingCategory.urlFr]
  )

  const handleTrackingOnClick = () => {
    trackEvent({
      /** The action will be dynamic, so we won't have it defined in the GA_EVENT list */
      action: `catId=${trendingCategory.categoryId}` as GaEvent,
      label: 'btn=menu_trending',
    })
  }

  return (
    <TrendingCategoriesLink
      data-testid="menu-trending-category"
      href={trendingLink}
      noStyle
      onClick={handleTrackingOnClick}
    >
      <TrendingCategoriesBlock>{t('mega_menu.trending_category.title')}</TrendingCategoriesBlock>
      {t(`mega_menu.trending_category.categories.${trendingCategory.categoryId}`)}
    </TrendingCategoriesLink>
  )
}
