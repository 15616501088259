import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKijijiLogo: React.FC<IconProps> = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 229.47 120.3"
      {...props}
    >
      <path
        fill="#373373"
        d="M65.67 46.38v46.26a1.64 1.64 0 001.64 1.64h13.08A1.64 1.64 0 0082 92.64V44.56a1.64 1.64 0 00-1.87-1.63l-13.04 1.82a1.64 1.64 0 00-1.42 1.63zm68.19 0v46.26a1.64 1.64 0 001.64 1.64h13.07a1.64 1.64 0 001.64-1.64V44.56a1.64 1.64 0 00-1.87-1.63l-13.07 1.82a1.64 1.64 0 00-1.41 1.63zm68.14 0v46.26a1.64 1.64 0 001.64 1.64h13.07a1.64 1.64 0 001.64-1.64V44.56a1.64 1.64 0 00-1.87-1.63l-13.07 1.82a1.64 1.64 0 00-1.41 1.63z"
      />
      <circle cx={73.85} cy={21.66} r={10.17} fill="#f8aa17" />
      <circle cx={107.95} cy={10.17} r={10.17} fill="#f1454f" />
      <circle cx={142.04} cy={21.66} r={10.17} fill="#1477D4" />
      <circle cx={176.13} cy={10.17} r={10.17} fill="#37a864" />
      <circle cx={210.23} cy={21.66} r={10.17} fill="#9b44ad" />
      <path
        fill="#373373"
        d="M32.24 59.57L53.85 34a1.64 1.64 0 00-1.25-2.72H36.34a1.64 1.64 0 00-1.28.61l-17.86 22a.48.48 0 01-.85-.3V6.3a1.64 1.64 0 00-1.64-1.64L1.64 6.58C.63 6.75 0 7.31 0 8.22v84.42a1.64 1.64 0 001.64 1.64h13.08a1.64 1.64 0 001.64-1.64V67a.51.51 0 01.93-.29l18.79 26.87a1.64 1.64 0 001.35.7h16a1.64 1.64 0 001.31-2.64L32.23 60a.37.37 0 01.01-.43zm82.01-28.12l-13.07 1.82a1.64 1.64 0 00-1.42 1.63v58.29a47.94 47.94 0 01-.26 5.08 12 12 0 01-1.13 4.08 6.56 6.56 0 01-2.43 2.65 8 8 0 01-4.2 1 19 19 0 01-2-.13l-.84-.12c-1.55-.32-2 .53-2.11 1 0 .22-.12.79-.12.79-.42 3-1.08 7.45-1.39 10a1.82 1.82 0 001.63 2.09c.51.09 1.06.17 1.72.26a40.58 40.58 0 004.56.32c4.71 0 8.59-.76 11.53-2.25a18.51 18.51 0 007-6 22.74 22.74 0 003.5-8.84 59.86 59.86 0 00.91-10.64V33.07a1.64 1.64 0 00-1.88-1.62zm68.59 1.34l-13.07-1.4A1.64 1.64 0 00168 33v60.19a47.94 47.94 0 01-.26 5.08 12 12 0 01-1.13 4.08 6.56 6.56 0 01-2.44 2.69 8 8 0 01-4.2 1 19 19 0 01-2-.13l-.84-.12c-1.55-.32-2 .53-2.11 1 0 .22-.12.79-.12.79-.42 3-1.08 7.45-1.39 10a1.82 1.82 0 001.63 2.09c.51.09 1.06.17 1.72.26a40.58 40.58 0 004.56.32c4.71 0 8.59-.76 11.53-2.25a18.51 18.51 0 007-6 22.74 22.74 0 003.5-8.84 59.86 59.86 0 00.91-10.64V34.43a1.64 1.64 0 00-1.52-1.64zm42.33 10.9H224v3h-.71v-3h-1.18V43h3.07zm4.3 2.96h-.72v-2.3l-.1.32-.82 2h-.6l-.82-2-.1-.32v2.3h-.71V43h.82l1 2.34.08.21.08-.21 1-2.34h.82z"
      />
    </Icon>
  )
}
export default SvgKijijiLogo
