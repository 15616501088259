import { Chevron } from './styled'

export type RotatingChevronProps = {
  /**
   * Determines whether the chevron is in the closed (pointing down) or open (pointing up) position
   */
  isOpen: boolean
  /**
   * Bottom margin of component
   */
  bottom?: string
  /**
   * Top margin of component
   */
  top?: string
  /**
   * Left margin of component
   */
  left?: string
  /**
   * Right margin of component
   */
  right?: string
}

/**
 * @description A chevron component that is rendered pointing down by default and rotates
 * up when the isOpen prop is true.
 */

export const RotatingChevron = ({
  isOpen = false,
  bottom = '0',
  top = '0',
  left = '0',
  right = '0',
  ...rest
}: RotatingChevronProps) => {
  return (
    <Chevron
      aria-hidden
      $isOpen={isOpen}
      $bottom={bottom}
      $top={top}
      $left={left}
      $right={right}
      {...rest}
    />
  )
}
