import { theme } from '@kijiji/theme'
import { type HTMLAttributes } from 'react'
import styled from 'styled-components'

import { CONDENSED_APP_WIDTH, FULL_MAX_APP_WIDTH, MAX_APP_WIDTH } from '@/constants/others'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type PageContainerWrapperProps = HTMLAttributes<HTMLDivElement> & {
  $bottom: string
  $top: string
  $isCondensed: boolean
  $isFullMax: boolean
}

export const PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING = theme.spacing.default

export const PageContainerWrapper = styled.div<PageContainerWrapperProps>(
  ({ theme, $bottom, $top, $isCondensed, $isFullMax }) => `
  margin: ${$top} auto ${$bottom};
  max-width: 100%;
  padding: 0 ${PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING};
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    max-width: ${($isCondensed && CONDENSED_APP_WIDTH) || ($isFullMax && FULL_MAX_APP_WIDTH) || MAX_APP_WIDTH};
    padding: ${$isCondensed ? '0 6rem' : `0 ${theme.spacing.husky}`};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    margin: ${theme.spacing.default} auto 3rem;
    max-width: ${($isCondensed && CONDENSED_APP_WIDTH) || ($isFullMax && FULL_MAX_APP_WIDTH) || MAX_APP_WIDTH};
    padding: ${$isCondensed ? '0 6rem' : '0 8rem'};
  }
`
)
