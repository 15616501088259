import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { Spacing } from '@/ui/atoms/spacing'
import {
  INPUT_BORDER_COLOR,
  INPUT_BORDER_WIDTH,
  INPUT_ERROR_COLOR,
} from '@/ui/constants/commonFormStyle'

export const FormControlContainer = styled(Spacing)(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.defaultSmall};
`
)

type StyledFormControlProps = {
  isFullWidth?: boolean
}

export const StyledFormControl = styled.div<StyledFormControlProps>(
  ({ isFullWidth }) => `
    border: 0;
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    min-width: ${isFullWidth && '100%'};
    padding: 0;
    position: relative;
    vertical-align: top;
`
)

type StyledFormControlInputProps = {
  endAdornment?: boolean
  hasError?: boolean
  isFocused?: boolean
  startAdornment?: boolean
}

export const styledBorderColor = (
  theme: ThemeProps,
  isFocused?: boolean,
  hasError?: boolean
) => {
  if (isFocused) {
    return theme.colors.blue.primary
  }
  if (hasError) {
    return INPUT_ERROR_COLOR(theme)
  }
  return INPUT_BORDER_COLOR(theme)
}

// Should only be used if adornment
export const StyledFormControlInput = styled.div<StyledFormControlInputProps>(
  ({ theme, startAdornment, endAdornment, isFocused, hasError }) => `
  align-items: center;
  background-color: ${theme.colors.white};
  border-color: ${styledBorderColor(theme, isFocused, hasError)};
  border-radius: ${theme.borderRadius.small};
  border-style: solid;
  border-width: ${INPUT_BORDER_WIDTH};
  cursor: text;
  display: inline-flex;
  inset: 0;
  padding-left: ${startAdornment ? theme.spacing.default : 0};
  padding-right: ${endAdornment ? theme.spacing.default : 0};
  position: relative;

  input {
    border: none;
  }

  & label {
    left: ${theme.spacing.defaultSmall};
  }

  & svg {
    width: ${theme.spacing.large};
    height: auto;
  }
  `
)
