import { PropsWithChildren, ReactNode } from 'react'

import { StyledSkipNav } from '@/ui/atoms/skip-nav/styled'

export type SkipNavLinkProps = PropsWithChildren<{
  contentId?: string
}>

const defaultId = 'skip-nav'

/**
 * SkipNavLink
 *
 * Renders a link that remains hidden until focused to skip to the main content.
 */
export const SkipNavLink = ({
  children,
  contentId = defaultId,
  ...props
}: SkipNavLinkProps) => {
  return (
    <StyledSkipNav>
      <a {...props} href={`#${contentId}`}>
        {children}
      </a>
    </StyledSkipNav>
  )
}

export type SkipNavContentProps = {
  children?: ReactNode
  id?: string
}

/**
 * SkipNavContent
 *
 * Renders a div as the target for the link.
 */
export const SkipNavContent = ({
  id = defaultId,
  ...props
}: SkipNavContentProps) => <div {...props} id={id} />
