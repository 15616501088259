import { isDominantCategorySearch } from '@/domain/srp/isDominantCategorySearch'
import {
  removeDomCatFromUrl,
  replaceEncodedForwardSlash,
  replaceEncodedWhitespaceWithPlusSign,
} from '@/domain/urls'

/**
 * Sanitizes the search URL, to be used in server-side props
 * eventually to be sent to the seo-decoder
 * @param url
 *
 * @returnsS sanitizedUrl ready to be used by BE decoder
 */
export const sanitizeSrpUrl = (url: string): string => {
  // TODO: Figure out what the heck this is trying to do
  if (!isDominantCategorySearch(url)) {
    url = removeDomCatFromUrl(url)
  }

  url = replaceEncodedForwardSlash(url)
  url = replaceEncodedWhitespaceWithPlusSign(url)

  return url
}
