import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

const trackHeight = '0.6rem'

export const SliderContainer = styled.div(
  ({ theme }) => `
  .rc-slider-rail {
    height: ${trackHeight};
  }
  .rc-slider-track {
    height: ${trackHeight};
    background-color: ${theme.colors.purple.light1};
  }
  .rc-slider-handle {
    width: ${theme.spacing.large};
    height: ${theme.spacing.large};
    border: 0.2rem solid ${theme.colors.purple.light1};
    box-shadow: 0 0.1rem 0.5rem rgba(55, 51, 115, 0.3);
    margin-top: -0.9rem;
  }
  .rc-slider-handle:hover {
    border-color: ${theme.colors.purple.light1};
  }
  .rc-slider-handle:focus, 
  .rc-slider-handle:active, 
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: ${theme.colors.purple.light1};
    box-shadow: 0 0 0 ${theme.spacing.mini} rgba(55, 51, 115, 0.5);
  }
  .rc-slider-dot {
    margin-left: 0;
    border: none;
    height: ${trackHeight};
    width: 0.1rem;
  }
  .rc-slider-mark-text {
    bottom: 2.8rem;
    color: ${theme.colors.grey.light1};
    ${styleFromTypography(theme.typography.body.small)};  
  }
  `
)

export const MinValue = styled.div(
  ({ theme }) => `
  color: ${theme.colors.grey.light1};
  margin-bottom: 1rem;
  ${styleFromTypography(theme.typography.body.small)};
`
)
export const MaxValue = styled.div(
  ({ theme }) => `
  color: ${theme.colors.grey.light1};
  float: right;
  margin-top: -2.8rem;
  ${styleFromTypography(theme.typography.body.small)};
`
)
