import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { ListItemProps } from './'

type ListItemBaseProps = RequiredPickMap<
  ListItemProps,
  'bulletColor' | 'size' | 'variant'
>

/**
 * ListItem 'bullet' variant
 */
type ListItemBulletVariantStyleProps = Pick<
  ListItemBaseProps,
  '$bulletColor' | '$size'
> & {
  theme: ThemeProps
}

const bulletFromSize = {
  xLarge: '0.8rem',
  large: '0.8rem',
  medium: '0.6rem',
  small: '0.6rem',
  xSmall: '0.6rem',
  xxSmall: '0.6rem',
}

const listItemBulletVariantStyle = ({
  $bulletColor,
  $size,
  theme,
}: ListItemBulletVariantStyleProps) => `
  padding-left: calc(
    ${bulletFromSize[$size]} + ${theme.spacing.default}
  );
  position: relative;

  &::before {
    background-color: ${$bulletColor};
    border-radius: ${theme.borderRadius.rounded};
    content: '';
    display: inline-block;
    font-weight: bold;
    height: ${bulletFromSize[$size]};
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${bulletFromSize[$size]};
  }
`

/**
 * ListItem 'icon' variant
 */
type ListItemIconVariantStyleProps = {
  theme: ThemeProps
}

const listItemIconVariantStyle = ({ theme }: ListItemIconVariantStyleProps) => `
  align-items: center;
  display: flex;
  position: relative;

  & svg {
    flex-shrink: 0;
    height: auto;
    width: ${theme.spacing.large};
  }

  > span {
    padding-left: ${theme.spacing.default};
  }
`

/**
 * ListItem 'base'
 */

export const ListItemBase = styled.li<ListItemBaseProps>(
  ({ $bulletColor, $size, $variant, theme }) => `
  ${styleFromTypography(theme.typography.body[$size])}

  list-style: none;
  flex-shrink: 0;

  ${
    $variant === 'bullet'
      ? listItemBulletVariantStyle({ $bulletColor, $size, theme })
      : ''
  }
  ${$variant === 'icon' ? listItemIconVariantStyle({ theme }) : ''}
`
)
