export type BrandedCategoryData = {
  hpCategory: number
  id: number
  label: string
  logo: string
  srpKeyword: string
  srpUrl?: string
  internalUrl?: string
}

export type BrandedSearchData = {
  id: number
  keywords: string[]
  label: string
  logo: string
  srpCategory: number
}

export type BrandedTileData = {
  backgroundLoggedIn: string
  backgroundLoggedOut: string
  brandedType?: string
  hpCategory: number
  id: number
  internalUrl?: string
  srpCategory?: number
  srpKeyword?: string
  srpUrl?: string
  title: string
}

export type CampaignType =
  | 'carproof'
  | 'dfp'
  | 'home-verified'
  | 'mortgage-calculator'
  | 'other'
  | 'rehoming-adoption'
  | 'stay-safe'
export type CampaignLabelType = 'other' | 'image' | 'text'
export type CampaignPlacement = 'custom' | 'vip' | 'srp'
export type CampaignCustomType =
  | 'app-loading-screen'
  | 'branded-category'
  | 'landing-page'
  | 'branded-search'
  | 'branded-tile'

export const CustomCampaignName: { [x: string]: CampaignCustomType } = {
  APP_LOADING_SCREEN: 'app-loading-screen',
  BRANDED_CATEGORY: 'branded-category',
  BRANDED_SEARCH: 'branded-search',
  BRANDED_TILE: 'branded-tile',
  LANDING_PAGE: 'landing-page',
}

export type CampaignForSaleBy = 'dealer' | 'owner'
export type CampaignPlatform = 'tablet' | 'mweb' | 'desktop' | 'mobile'
