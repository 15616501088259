import SvgClose from '@kijiji/icons/src/icons/Close'
import { useTranslation } from 'next-i18next'
import { type FC, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import {
  PSABannerBody,
  PSABannerCloseButton,
  PSABannerContainer,
  PSABannerImageContainer,
  PSABannerLink,
} from '@/components/shared/psa-banner/styled'
import { usePSABanner } from '@/components/shared/psa-banner/usePSABanner'
import { type PSAKeys, PSA_BANNER } from '@/constants/psaBanner'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'

export type PSABannerProps = {
  /**
   * PSA configuration Key
   * @default 'appDownloadPSA'
   */
  psaKey?: PSAKeys
}

/**
 * The PSA banner is used for different marketing campaigns
 * Its default data is the App Marketing
 */
export const PSABanner: FC<PSABannerProps> = ({ psaKey = 'appDownloadPSA' }) => {
  const { t } = useTranslation('common')
  const { colors } = useTheme()
  const firstLoad = useRef<boolean>(true)

  const { isVisible, handlePSAClose } = usePSABanner(psaKey)

  useEffect(() => {
    if (firstLoad.current === true && isVisible) {
      trackEvent({ action: GA_EVENT.PSALoaded, label: `PSA=${psaKey};` })
      firstLoad.current = false
    }
  }, [psaKey, isVisible])

  const { intlKey, hasHighlightedTitle } = PSA_BANNER[psaKey]

  const baseIntlKey = `psa_banner.${intlKey}`

  /* Our internationalization library returns a string regardless of whether a translation exists. To determine if the translation exists,
  we must compare the string our library returns with the translation key. */
  const linkHref =
    t(`${baseIntlKey}.link.href`) !== `${baseIntlKey}.link.href`
      ? t(`${baseIntlKey}.link.href`)
      : null

  const bannerTitle =
    t(`${baseIntlKey}.title`) !== `${baseIntlKey}.title` ? t(`${baseIntlKey}.title`) : null

  const getImage = () => {
    const imageKey = `${baseIntlKey}.image`

    const hasImage = !t(`${imageKey}.src`).startsWith('psa_banner')

    if (hasImage) {
      const src = t(`${imageKey}.src`)
      const alt = t(`${imageKey}.alt`).startsWith('psa_banner') ? t(`${imageKey}.alt`) : undefined

      return { src, alt }
    }

    return { src: undefined, alt: '' }
  }

  const trackButtonClick = () => {
    trackEvent({ action: GA_EVENT.PSAClick, label: `PSA=${psaKey};` })
  }

  const handleDismissBanner = () => {
    handlePSAClose()
    trackEvent({ action: GA_EVENT.PSADismissed, label: `PSA=${psaKey};` })
  }

  const { src, alt } = getImage()

  if (!isVisible) return null

  return (
    <PSABannerContainer data-testid="psa-banner" role="complementary">
      <PSABannerImageContainer>
        <ImageCustom
          aria-label={'Kijiji'}
          src={src ?? '/next-assets/images/kijiji-logo-white.svg'}
          alt={alt ?? 'Kijiji'}
          fill
        />
      </PSABannerImageContainer>

      <PSABannerBody>
        {bannerTitle && (
          <BodyText size="large" color={hasHighlightedTitle ? colors.yellow.light2 : colors.white}>
            {bannerTitle}
          </BodyText>
        )}
        <BodyText size="small" color={colors.white} weight="regular">
          {t(`${baseIntlKey}.subtitle`)}
        </BodyText>
      </PSABannerBody>

      {linkHref ? (
        <PSABannerLink href={linkHref} onClick={trackButtonClick} target="_blank">
          {t(`${baseIntlKey}.link.label`)}
        </PSABannerLink>
      ) : null}

      <PSABannerCloseButton aria-label={t('psa_banner.close.label')} onClick={handleDismissBanner}>
        <SvgClose aria-hidden />
      </PSABannerCloseButton>
    </PSABannerContainer>
  )
}
