/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'

type DebounceFunction = (...args: any[]) => void

export const useDebounce = (delay: number) => {
  const actionTimeoutRef = useRef<number | undefined>()

  const debounce =
    (callback: DebounceFunction) =>
    (...args: any[]) => {
      if (actionTimeoutRef.current) {
        clearTimeout(actionTimeoutRef.current)
      }

      const timeout = window.setTimeout(() => {
        callback(...args)
      }, delay)

      actionTimeoutRef.current = timeout
    }

  return debounce
}
