import { KeyboardEvent } from 'react'

export const onEnterPress = (
  evt: KeyboardEvent,
  callback: (...ags: unknown[]) => void | Promise<void>
) => {
  if (evt.key === 'Enter') {
    callback()
  }
}

export const onEscapePress = (
  evt: KeyboardEvent,
  callback: (...ags: unknown[]) => void | Promise<void>
) => {
  if (evt.key === 'Escape') {
    callback()
  }
}
