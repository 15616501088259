import {
  MAXIMUM_RADIUS_VALUE,
  MINIMUM_RADIUS_VALUE,
  RADIUS_SLIDER_HALF_POINT_KMS,
} from '@/constants/location'
import { getLinearToPowerCurveConverter } from '@/utils/math'

const POWER_CURVE_DISTANCE_MODIFIER = 10

const linearToPowerCurveTransformer = getLinearToPowerCurveConverter(
  MINIMUM_RADIUS_VALUE,
  MAXIMUM_RADIUS_VALUE,
  RADIUS_SLIDER_HALF_POINT_KMS
)

/**
 * Transformer for the location slider to change values in a power curve instead of in a linear way.
 *
 * @param input Value to be converted from and to a power curve
 * @param inverse Defines if value will be converted "to" or "from" a power curve
 * @returns
 */
export const radiusSliderValueTransformer = (input: number, inverse = false) => {
  if (inverse) {
    return linearToPowerCurveTransformer(input, inverse) * POWER_CURVE_DISTANCE_MODIFIER
  }
  return Math.round(linearToPowerCurveTransformer(input / POWER_CURVE_DISTANCE_MODIFIER))
}
