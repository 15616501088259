export const UserTypes = {
  ADMARKT: 'ADMARKT',
  FSBO: 'FSBO',
  PROFESSIONAL: 'PROFESSIONAL',
}

export type UserType = keyof typeof UserTypes

//TODO: determine all user types returned by the back-end (we are not just returning FSBO and PROFESSIONAL)
export const isAdmarkt = (userType: string) => userType === UserTypes.ADMARKT
export const isProfessional = (userType: string) => userType === UserTypes.PROFESSIONAL
export const isFSBO = (userType: string) => userType === UserTypes.FSBO
