import styled from 'styled-components'

import { getAvatarBackgroundColor } from '@/features/profile/utils/getAvatarBackgroundColor'

export const ProfileAvatarContainer = styled.div<{
  size: string
  userId?: string
  iconSize: string
}>(
  ({ iconSize, size, theme, userId = '0' }) => `
  align-items: center;
  background: ${getAvatarBackgroundColor(userId, theme.colors)};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  height: ${size};
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${size};

  & > svg {
    height: ${iconSize};
    width: auto;
  }
`
)
