import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgError: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.333c9.205 0 16.666 7.462 16.666 16.667 0 9.205-7.461 16.667-16.666 16.667S3.333 29.205 3.333 20c0-9.205 7.462-16.667 16.667-16.667z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 23.333c-.92 0-1.667-.746-1.667-1.666v-8.334a1.667 1.667 0 013.333 0v8.334c0 .92-.746 1.666-1.666 1.666zM20 26.665v.001h.833L20 26.665zm0 1.668a1.67 1.67 0 01-1.667-1.666A1.67 1.67 0 0120 25a1.67 1.67 0 011.666 1.667A1.67 1.67 0 0120 28.333z"
        fill="#fff"
      />
    </Icon>
  )
}
export default SvgError
