import { type UserLocation } from '@kijiji/generated/graphql-types'

import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'
import { RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { metresToKilometres } from '@/utils/conversions'

/**
 * Converts a location of type RecentSearchLocation to a location of type UserLocation for use in webapp
 * @param recentSearchLocation
 * @returns
 */
export const getLocationFromRecentSearchLocation = (
  recentSearchLocation: RecentSearchLocation
): UserLocation => {
  return {
    isRegion: recentSearchLocation.isProvinceOrCountry || recentSearchLocation.isSearchAllArea,
    id: recentSearchLocation.internalId,
    name: { en_CA: recentSearchLocation.internalName, fr_CA: recentSearchLocation.internalName },
    area:
      recentSearchLocation.latitude && recentSearchLocation.longitude
        ? {
            latitude: recentSearchLocation.latitude,
            longitude: recentSearchLocation.longitude,
            //FES stores the location radius in m
            radius: recentSearchLocation.mapRadius
              ? metresToKilometres(recentSearchLocation.mapRadius)
              : RADIUS_SLIDER_HALF_POINT_KMS,
            //TODO: At the moment there is no way for us to extract location translations from the RecentSearchLocations localStorage object. We are only provided with a single location "internal name"
            address: recentSearchLocation.address,
          }
        : null,
  }
}
