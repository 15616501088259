import { FC } from 'react'

import { FieldError } from '@/ui/atoms/field-error'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { HelperText } from './HelperText'
import { FormControlContainer, StyledFormControl } from './styled'

type FormControlProps = {
  /**
   * Defines if input takes full with of parent container
   */
  isFullWidth?: boolean
  /**
   * Id of field error
   * */
  errorId?: string
  children: React.ReactNode
} & Pick<CommonInputFieldProps, 'id' | 'helperText' | 'error' | 'bottom'>

export const FormControl: FC<FormControlProps> = ({
  bottom = '2rem',
  children,
  error,
  helperText,
  id,
  isFullWidth,
  errorId,
  ...rest
}) => {
  return (
    <FormControlContainer mBottom={bottom} {...rest}>
      <StyledFormControl isFullWidth={isFullWidth}>
        {children}
      </StyledFormControl>

      <FieldError id={errorId} error={error} />

      {!!helperText && <HelperText id={id}>{helperText}</HelperText>}
    </FormControlContainer>
  )
}
