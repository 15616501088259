import qs from 'query-string'

/**
 * Check if the search is a dominant category search
 * There will be dc=true in the query parameter if it is
 *
 * @param {string} asPath
 * @returns {boolean}
 */
export const isDominantCategorySearch = (asPath: string): boolean => {
  const { query } = qs.parseUrl(asPath)

  return query.dc === 'true'
}
