// import { Range as RcRange, RangeProps as RcRangeProps } from 'rc-slider'
import Slider, { SliderProps } from 'rc-slider'
import { FC } from 'react'

import { MaxValue, MinValue, SliderContainer } from './styled'

type ExtraProps = {
  valueFormat?: (value: number) => number | string
  unboundMaxLabel?: boolean
}

export type RangeProps = SliderProps & ExtraProps

/**
 * @description Provides basic Range component. See https://slider-react-component.vercel.app/#api for list of options.
 *
 * @param valueFormat - custom formatter for values, e.g. to make a price range ($0 - $100)
 * @param unboundMaxLabel - adds '+' at the end of Max label
 */

export const RANGE_DEFAULT_MIN = 0
export const RANGE_DEFAULT_MAX = 100

export const Range: FC<RangeProps> = ({
  valueFormat,
  unboundMaxLabel = false,
  min = RANGE_DEFAULT_MIN,
  max = RANGE_DEFAULT_MAX,
  ...props
}) => {
  const minValue = valueFormat ? valueFormat(min) : min
  const maxValue = valueFormat ? valueFormat(max) : max

  return (
    <SliderContainer>
      <MinValue>{minValue}</MinValue>
      <MaxValue>{`${maxValue}${unboundMaxLabel ? '+' : ''}`}</MaxValue>
      <Slider range={true} allowCross={false} min={min} max={max} {...props} />
    </SliderContainer>
  )
}
