import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWarningSolid: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <clipPath id="warning-solid_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
      <g clipPath="url(#warning-solid_svg__a)">
        <path
          d="M10.92 2.002a1.228 1.228 0 012.16 0l10.442 19.337a1.228 1.228 0 01-1.081 1.812H1.559c-.93 0-1.523-.994-1.08-1.812z"
          fill="#f8aa17"
        />
        <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.8}>
          <path d="M12 11v5M12 18.8v.1" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgWarningSolid
