export const snakeCase = (string: string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
}

export const capitalizeString = (string: string) =>
  string
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')

export const capitalizeFirstWord = (string: string) => {
  const lower = string.trim().toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.substring(1)
}

/**
 * Truncate string based on max-character defined and add ellipsis at the end.
 * Ellipsis will count as 3 characters at the end of the string.
 */
export const truncateStringWithEllipsis = (maxLength: number, text: string) => {
  /**
   * If text fits the possible max length - no ellipsis is added and the entire text can be returned
   *  */
  if (text.length <= maxLength) return text

  /**
   * If text doesn't fit the maxLength - The ellipsis will count as 3 more characters
   * The entire string returned needs to fit into maxLength requirement
   */
  return `${text.substring(0, maxLength - 3)}...`
}

/**
 * Converts an array of strings to all lowercase.
 *
 * @param {string[]} [strings] - An array of strings.
 * @returns {string[]} An array of lowercase strings.
 */
export const convertToLowerCase = (strings: string[]): string[] => {
  return strings?.map((string) => string?.toLowerCase()) || []
}

/**
 * Replaces all '&' characters with 'and' in an array of strings.
 *
 * @param {string[]} [strings] - An array of strings.
 * @returns {string[]} An array of strings with '&' replaced by 'and'.
 */
export const replaceAmpersands = (strings: string[]): string[] => {
  return strings?.map((string) => string?.replace(/&/g, 'and')) || []
}

/**
 * Removes all spaces from an array of strings.
 *
 * @param {string[]} [strings] - An array of strings.
 * @returns {string[]} An array of strings with all spaces removed.
 */
export const removeSpaces = (strings: string[]): string[] => {
  return strings?.map((string) => string?.replace(/\s/g, '')) || []
}

/**
 * Removes empty HTML paragraphs from a string. Catches &nbsp; and whitespace.
 */
export const replaceEmptyParagraphs = (string: string) => {
  return string.replace(/<p>\s*(&nbsp;)?\s*<\/p>/gi, '')
}
