import {
  type AdditionalFlags,
  type AttributeFilterInput,
  type DateFilterInput,
  type DateRangeFilterInput,
  type RangeFilterInput,
  type ToggleFilterInput,
} from '@kijiji/generated/graphql-types'

import { FilterKeysEnum } from '@/hooks/srp/useSearchActions'
import {
  type AppliedFilters,
  isAppliedAdditionalFlagsFilter,
  isAppliedAttributeFilter,
  isAppliedDateFilter,
  isAppliedDateRangeFilter,
  isAppliedRangeFilter,
  isAppliedToggleFilter,
  stripTypename,
} from '@/types/search'

/**
 * Categorizes the applied filters based on their sub-types.
 *
 * @param {AppliedFilters} appliedFilters - The array of applied filters.
 * @returns {CategorizedFilters} The categorized filters object.
 */
export const categorizeAppliedFilters = (appliedFilters?: AppliedFilters) => {
  const categorizedFilters = (appliedFilters || []).reduce(
    (acc, filter) => {
      if (isAppliedAttributeFilter(filter)) {
        acc[FilterKeysEnum.ATTRIBUTE_FILTERS].push(stripTypename(filter))
      } else if (isAppliedRangeFilter(filter)) {
        acc[FilterKeysEnum.RANGE_FILTERS].push(stripTypename(filter))
      } else if (isAppliedDateRangeFilter(filter)) {
        acc[FilterKeysEnum.DATE_RANGE_FILTERS].push(stripTypename(filter))
      } else if (isAppliedDateFilter(filter)) {
        acc[FilterKeysEnum.DATE_FILTERS].push(stripTypename(filter))
      } else if (isAppliedToggleFilter(filter)) {
        acc[FilterKeysEnum.TOGGLE_FILTERS].push(stripTypename(filter))
      } else if (isAppliedAdditionalFlagsFilter(filter)) {
        acc[FilterKeysEnum.ADDITIONAL_FLAG_FILTERS] = filter.flags
      }

      return acc
    },
    {
      [FilterKeysEnum.ADDITIONAL_FLAG_FILTERS]: [] as AdditionalFlags[],
      [FilterKeysEnum.ATTRIBUTE_FILTERS]: [] as AttributeFilterInput[],
      [FilterKeysEnum.RANGE_FILTERS]: [] as RangeFilterInput[],
      [FilterKeysEnum.DATE_RANGE_FILTERS]: [] as DateRangeFilterInput[],
      [FilterKeysEnum.DATE_FILTERS]: [] as DateFilterInput[],
      [FilterKeysEnum.TOGGLE_FILTERS]: [] as ToggleFilterInput[],
    }
  )

  return categorizedFilters
}
