import { isPositiveInteger } from '@kijiji/number/isPositiveInteger'

/**
 * Checks if the value is a valid number for a location ID.
 * It won't match it against all locations from our codebase, however it will prevent us from
 * checking if the number is !== 0 every time we use it.
 *
 * @param id
 */
export const isValidLocation = (id?: number | null): id is number => {
  return isPositiveInteger(id)
}
