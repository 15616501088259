import { type NextRouter } from 'next/router'

import { sanitizeSrpUrl } from '@/domain/srp/sanitizeSrpUrl'

/** When a new search is initiated on the search results page (SRP), the req.url does not match
 * that of the browser and must be composed using search params.
 */
export const getSrpComposedUrl = (query: NextRouter['query']): URL => {
  const webAppUrl = process.env.NEXT_PUBLIC_WEBAPP_URL
  if (!webAppUrl) {
    throw new Error('NEXT_PUBLIC_WEBAPP_URL is not defined')
  }

  // When a shallow page load is performed, the request is formatted differently to only
  // request the getServerSideProps data. In this case, we need to reconstruct the URL.
  const domain = webAppUrl.endsWith('/') ? webAppUrl : `${webAppUrl}/`
  const composedUrl = `${domain}b-${query?.categoryOrLocation?.[0]}/${query.params}`
  let params = '?'
  for (const key in query) {
    const value = query[key]
    if (key === 'params' || key === 'categoryOrLocation') {
      continue
    }
    if (value !== undefined) {
      const newParam = `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}&`
      params += newParam
    }
  }
  params = params.slice(0, -1)

  const sanitizedHref = sanitizeSrpUrl(`${composedUrl}${params}`)
  return new URL(sanitizedHref)
}
