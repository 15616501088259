import { type GetPsaDismissedQuery } from '@kijiji/generated/graphql-types'
import { isAfter, isBefore } from 'date-fns'
import qs from 'query-string'

import { type PSAKeys, PSA_BANNER } from '@/constants/psaBanner'

export const isPSABannerVisible = ({
  asPath,
  data,
  psaKey,
  campaignHref,
}: {
  asPath: string
  campaignHref?: string
  data?: GetPsaDismissedQuery
  psaKey: PSAKeys
}): boolean => {
  /**
   * If there is no state defined then the user never dismissed the banner,
   * so it will fallback to check the start/end date of the campaign
   */
  if (data?.dismissedStates[psaKey]) {
    return false
  }

  /** It should not show banner on campaign's page */
  /** Removes query parameter */
  const { url } = qs.parseUrl(`${asPath}`)
  if (url !== '/' && campaignHref?.includes(url)) {
    return false
  }

  /** Check the start / end date of the campaign */
  const campaign = PSA_BANNER[psaKey]
  const today = new Date()

  if (campaign.startDate && isBefore(today, campaign.startDate)) {
    return false
  }

  if (campaign.endDate && isAfter(today, campaign.endDate)) {
    return false
  }

  return true
}
