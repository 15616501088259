import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { setLocaleCookie } from '@/domain/locale'
import { useLocale } from '@/hooks/useLocale'

export type ToggleLocaleProps = {
  children: JSX.Element
  onClick?: () => void
}

/**
 * This component is responsible for wrapping any Link to toggle locale in the application
 * It will update the cookie shared by legacy + nwa and refresh the page
 *
 * This component should be agnostic of specific locale placement logic
 */
export const ToggleLocale: FC<ToggleLocaleProps> = ({ children, onClick, ...rest }) => {
  const { reload } = useRouter()
  const { t } = useTranslation('common')

  const {
    localeSwap: { cookieLocale },
  } = useLocale()

  const handleToggleLocale = () => {
    // Trigger onClick from props if any
    onClick?.()

    setLocaleCookie(cookieLocale)
    reload()
  }

  return (
    <button
      onClick={handleToggleLocale}
      aria-label={t('language_toggle.text.long')}
      data-testid="language-toggle"
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
}
