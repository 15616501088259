import { useState } from 'react'

import {
  getBrandedSuggestion,
  getCategorySuggestions,
  separateSearchSuggestionByType,
} from '@/domain/headerSearchBar'
import { useBrandedSearchCampaign } from '@/hooks/campaigns/useBrandedSearchCampaign'
import { useLocale } from '@/hooks/useLocale'
import { type SearchBarOption } from '@/ui/molecules/search-bar'
import { useAlgoliaSearch } from '@/utils/algolia/algoliaSearch'

export const useSearchSuggestions = ({ categoryId }: { categoryId: number }) => {
  const { apiLocale } = useLocale()

  /** Initialize algolia */
  const { getSearchSuggestions } = useAlgoliaSearch()

  /** Get branded-search custom campaigns */
  const { campaigns } = useBrandedSearchCampaign({ categoryId })

  const [suggestionOptions, setSuggestionOptions] = useState<SearchBarOption[]>([])

  const getSuggestions = async (query: string) => {
    //If there is no search text, then suggestions should be empty
    if (!query) {
      setSuggestionOptions([])
      return
    }

    const suggestions = await getSearchSuggestions(query)
    const brandedSuggestionByKeyword = getBrandedSuggestion(campaigns, query)

    /**
     * TODO: Figure out what we are going to do with this autos feature after the release
     * We might want to remove it moving forward. For now, we are just not adding to the list of suggestions
     *
     * This comment should lead us to the PR that removed the autos functionality in case we want to add it back
     */
    const { generalResults } = separateSearchSuggestionByType(suggestions)

    const categoryOptions = getCategorySuggestions(generalResults, apiLocale)

    const kijijiSuggestions = [...categoryOptions, ...brandedSuggestionByKeyword]

    setSuggestionOptions(kijijiSuggestions)
  }

  return { getSuggestions, suggestions: suggestionOptions }
}
