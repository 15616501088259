import { useTranslation } from 'next-i18next'
import { type PropsWithChildren, useCallback, useMemo } from 'react'

import { type LocationErrorType, LOCATION_ERROR_TYPE } from '@/constants/location'
import { Tooltip } from '@/ui/atoms/tool-tip'

export type LocationTooltipError = {
  tooltipId: string
  tooltipText: string
}

export type ToolTipErrorProps = PropsWithChildren<{
  /**
   * Active error key
   * */
  activeError: LocationErrorType | undefined
  /**
   * Callback function that clears the activeError in the parent component and focuses the input
   * */
  clearError: () => void
}>

export const TooltipError = ({ activeError, clearError, children }: ToolTipErrorProps) => {
  const { t } = useTranslation(['common'])

  const tooltipErrors: Record<string, LocationTooltipError> = useMemo(() => {
    return {
      [LOCATION_ERROR_TYPE.GOOGLE_LOCATION_ERROR]: {
        tooltipId: 'google-location-error-message',
        tooltipText: t('modals.search_location.alert_messages.invalid_location_input'),
      },
      [LOCATION_ERROR_TYPE.LIVE_LOCATION_ERROR]: {
        tooltipId: 'live-location-error-message',
        tooltipText: t('modals.search_location.alert_messages.location_services_disabled'),
      },
      [LOCATION_ERROR_TYPE.LOCATION_NOT_SELECTED_ERROR]: {
        tooltipId: 'location-not-selected-error-message',
        tooltipText: t('modals.search_location.alert_messages.location_not_selected'),
      },
    }
  }, [t])

  const hideTooltipError = useCallback(() => {
    if (!activeError) {
      return undefined
    }

    return clearError()
  }, [activeError, clearError])

  const tooltipProps = useCallback(() => {
    return {
      isVisible: !!activeError,
      tooltipId: (activeError && tooltipErrors[activeError].tooltipId) || '',
      tooltipText: (activeError && tooltipErrors[activeError].tooltipText) || '',
      onClose: hideTooltipError,
      closeButtonLabel: t('modals.search_location.alert_messages.close_label'),
    }
  }, [t, hideTooltipError, activeError, tooltipErrors])

  return (
    <Tooltip {...tooltipProps()} orientation="top-right">
      {children}
    </Tooltip>
  )
}
