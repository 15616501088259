/** SVG Path for marker on the map */
export const markerSvgPath =
  'M23.3333 4.00007C20.8112 1.62043 17.4674 0.306815 14 0.333404C10.5325 0.306815 7.1888 1.62043 4.66666 4.00007C3.37559 5.25313 2.35467 6.75719 1.66675 8.41965C0.978835 10.0821 0.638485 11.8678 0.666655 13.6667C0.666655 17.3167 2.83332 21.7834 7.11665 27.0001C9.06918 29.3314 11.1857 31.5203 13.45 33.5501C13.6036 33.6804 13.7985 33.752 14 33.752C14.2015 33.752 14.3964 33.6804 14.55 33.5501C16.8143 31.5203 18.9308 29.3314 20.8833 27.0001C25.1667 21.7834 27.3333 17.3167 27.3333 13.6667C27.3615 11.8678 27.0211 10.0821 26.3332 8.41965C25.6453 6.75719 24.6244 5.25313 23.3333 4.00007ZM14 18.6667C12.8463 18.6667 11.7184 18.3246 10.7592 17.6836C9.79988 17.0427 9.0522 16.1316 8.61069 15.0657C8.16918 13.9998 8.05366 12.8269 8.27874 11.6954C8.50382 10.5638 9.05939 9.52442 9.8752 8.70861C10.691 7.89281 11.7304 7.33724 12.862 7.11216C13.9935 6.88708 15.1664 7.0026 16.2323 7.44411C17.2982 7.88562 18.2093 8.63329 18.8502 9.59258C19.4912 10.5519 19.8333 11.6797 19.8333 12.8334C19.8333 14.3805 19.2187 15.8642 18.1248 16.9582C17.0308 18.0522 15.5471 18.6667 14 18.6667Z'

/** Latitude-Longitude bounds of canada */
export const geographicalBoundsOfCanada = {
  south: 83,
  north: 42,
  east: 53,
  west: 141,
}
