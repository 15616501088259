import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInformation: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 26 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#1477d4" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.938 1v1.885M25 12.309h-2.01M21.733 4.141l-1.256 1.257M4.142 4.141l1.256 1.257M2.885 12.309H1M16.079 21.733l.125-2.639c0-1.005.252-1.508.754-2.387.126-.126.252-.377.377-.628.126-.126.251-.377.251-.503.378-.628.629-1.13.755-1.885.125-.502.125-.88.125-1.382 0-3.267-2.513-5.78-5.654-5.78-3.142 0-5.655 2.639-5.655 5.78 0 .503 0 .88.126 1.382.126.754.377 1.257.754 1.885.125.126.125.251.251.503.126.25.251.377.377.628.503.88.754 1.382.754 2.387l.126 2.64m6.534-1.886H9.796" />
        <path
          d="M16.079 21.733c-.377 1.382-1.634 2.513-3.142 2.513s-2.764-1.13-3.141-2.513"
          strokeMiterlimit={10}
        />
      </g>
    </Icon>
  )
}
export default SvgInformation
