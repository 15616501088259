import { type ThemeProps } from '@kijiji/theme'

import { getNumericUserId } from '@/features/profile/utils/getNumericUserId'

const avatarColorsList = (colors: ThemeProps['colors']) => [
  colors.blue.light4,
  colors.violet.light4,
  colors.yellow.light4,
  colors.purple.light4,
  colors.red.light4,
]

/**
 * This helper function defines which color we should use for the Avatar BG based on the user's ID
 */
export const getAvatarBackgroundColor = (userId: string, colors: ThemeProps['colors']) => {
  const colorsList = avatarColorsList(colors)

  const id = getNumericUserId(userId)
  const colorIndex = id % colorsList.length

  return colorsList[colorIndex]
}
