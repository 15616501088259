import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSearch: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={10} cy={10} r={6.5} stroke="#373373" />
      <path d="M20.146 20.854a.5.5 0 00.708-.708zm-6-6l6 6 .708-.708-6-6z" fill="#373373" />
    </Icon>
  )
}
export default SvgSearch
