import { FC, HTMLAttributes, ReactNode, RefObject } from 'react'

import { isEmpty } from '@/ui/helpers/string'
import { useHasValue } from '@/ui/hooks/useHasValue'
import { useIsFocused } from '@/ui/hooks/useIsFocused'

import { StyledFormControlInput } from './styled'

export type FormControlInputProps = {
  /**
   * Indicates if the input should have a button or icon at the end
   */
  endAdornment?: ReactNode
  /**
   * Error message content
   */
  error?: string
  /**
   * Reference of the input this component is controlling
   */
  inputRef: RefObject<HTMLInputElement>
  /**
   * Indicates if the input should have a button or icon at the beginning
   */
  startAdornment?: ReactNode
} & HTMLAttributes<HTMLDivElement>

/**
 * Wrapper to be used when adding a button or icon at the beginning or end of an input
 */
export const FormControlInput: FC<FormControlInputProps> = ({
  startAdornment,
  endAdornment,
  children,
  inputRef,
  error,
  ...rest
}) => {
  const hasErrors = !isEmpty(error)
  const isFocused: boolean = inputRef && useIsFocused(inputRef)
  const hasValue: boolean = inputRef && useHasValue(inputRef)

  return (
    <StyledFormControlInput
      endAdornment={!!endAdornment}
      hasError={hasErrors}
      isFocused={isFocused || hasValue}
      startAdornment={!!startAdornment}
      {...rest}
    >
      {startAdornment}
      {children}
      {endAdornment}
    </StyledFormControlInput>
  )
}
