import { theme } from '@kijiji/theme'
import { type EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import throttle from 'lodash/throttle'
import dynamic from 'next/dynamic'
import {
  type HTMLAttributes,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  CarouselDots,
  LegacyCarouselContainer,
  LegacyCarouselViewport,
  List,
  PrevNextBtnWrapper,
  Slide,
} from '@/components/shared/legacy-carousel/styled'
import {
  type CarouselArrowsProps,
  type CarouselCallbackOnScroll,
} from '@/components/shared/legacy-carousel/types'
import { MEDIA_QUERIES_SHORT } from '@/ui/constants/mediaQueries'

const DotButton = dynamic(
  () => import('../carousel-buttons/CarouselButtons').then((mod) => mod.DotButton),
  {
    ssr: false,
  }
)
const NextButton = dynamic(
  () => import('../carousel-buttons/CarouselButtons').then((mod) => mod.NextButton),
  {
    ssr: false,
  }
)
const PrevButton = dynamic(
  () => import('../carousel-buttons/CarouselButtons').then((mod) => mod.PrevButton),
  {
    ssr: false,
  }
)

export type LegacyCarouselProps = CarouselArrowsProps & {
  /**
   * Set callback function to be triggered when user hits a certain % of the scrollable content
   */
  callbackOnScroll?: CarouselCallbackOnScroll
  /**
   * Enables momentum scrolling. The duration of the continued scrolling is proportional to how vigorous the drag gesture is.
   * This option helps remove snap when loading more slides dynamically
   */
  dragFree?: boolean
  /**
   * Custom props for the list (ul) node
   * */
  listCustomProps?: HTMLAttributes<HTMLUListElement> & { ref: MutableRefObject<null> }
  /**
   * Should be set to true if slides passed as props are already a list item
   * */
  preventNestedListItem?: boolean
  /**
   * Tiles/Slides to be displayed in the carousel
   */
  slides: ReactNode[]
  /**
   * Defines slide sizes per breakpoints depending on how many it should be shown
   * If no definition is passed, it will take default size of the slides
   * i.e. [desktop, tablet, mobile] -> [6, 4, 2]
   */
  slidesToShow?: [number, number, number]
  /**
   * Custom css styling for slides
   * It helps adding extra breakpoint or custom definitions for exceptions
   */
  slideCustomStyle?: string
  /**
   * Defines if navigation dots should be shown
   */
  shouldShowDots?: boolean
  /**
   * Number of slides to scroll at different breakpoints
   * i.e. [desktop, tablet, mobile] -> [6, 4, 2]
   */
  slidesToScroll?: [number, number, number]
  /**
   * name for generating unique keys
   */
  name: string
  /**
   * Remove right-side padding
   * When the carousel is used as a section on a page, it will automatically add the margins to fit the max page size
   * In some use-cases we don't need this initial padding
   */
  removeHorizontalSpacing?: boolean
}

/**
 * This is a reworking of ShoppingAdsCarousel for the Home page rebuild
 * to make it reusable and look more like the FES carousel
 * TODO: We can revisit to create to create ShoppingAds variant
 */
/**
 * @deprecated This component was created to be used in the Homepage Rebuild, to match FES 1:1
 * Use the ListingsCarousel component instead to match our UI patterns on NWA
 */
export const LegacyCarousel = ({
  callbackOnScroll,
  dragFree,
  listCustomProps,
  name,
  preventNestedListItem,
  removeHorizontalSpacing,
  shouldShowArrows = [true, true, false],
  shouldShowDots = false,
  slideCustomStyle,
  slides,
  slidesToScroll = [6, 4, 2],
  slidesToShow,
  useLegacyLgDesktopBreakpoint = false,
}: LegacyCarouselProps) => {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const showcaseOptions: EmblaOptionsType = {
    active: true,
    inViewThreshold: 0.5,
    skipSnaps: dragFree,
    containScroll: 'trimSnaps',
    dragFree,
    breakpoints: dragFree
      ? undefined
      : {
          /**
           * embla-carousel requires media queries to be in the format:
           * (min-width: 1024px) instead of @media screen and (min-width: 1024px)
           */
          [MEDIA_QUERIES_SHORT(theme).small]: { slidesToScroll: slidesToScroll[2] },
          [MEDIA_QUERIES_SHORT(theme).medium]: { slidesToScroll: slidesToScroll[1] },
          [MEDIA_QUERIES_SHORT(theme).large]: { slidesToScroll: slidesToScroll[0] },
        },
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(showcaseOptions, [WheelGesturesPlugin()])
  const [slideToTriggerLoadMore, setSlideToTriggerLoadMore] = useState<number | undefined>()

  const handleCallbackOnScroll = () => {
    if (!emblaApi || !callbackOnScroll) return

    const slidesInView = emblaApi.slidesInView()

    const { percentage, callbackFn } = callbackOnScroll
    const slidesCount = slides.length
    const triggerOnSlide = Math.floor((slidesCount * percentage) / 100)

    if (triggerOnSlide === slideToTriggerLoadMore) return

    if (slidesInView?.includes(triggerOnSlide)) {
      callbackFn()
      setSlideToTriggerLoadMore(triggerOnSlide)
    }
  }

  useEffect(() => {
    /**
     * If user selects arrows or scrolls carousel
     */
    const handleOnScroll = throttle(handleCallbackOnScroll, 800, { leading: true })
    emblaApi?.on('scroll', handleOnScroll)
    emblaApi?.on('select', handleOnScroll)

    return () => {
      emblaApi?.off('scroll', handleOnScroll)
      emblaApi?.off('select', handleOnScroll)
    }
  })

  useEffect(() => {
    if (!emblaApi) return

    emblaApi.reInit() // hard reset carousel for options changes
  }, [emblaApi, slides.length])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())

    // add embla event listeners
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)

    return () => {
      // remove embla event listeners
      emblaApi.off('select', onSelect)
      emblaApi.off('reInit', onSelect)
    }
  }, [emblaApi, onSelect])

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev(dragFree)
  }, [emblaApi, dragFree])

  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext(dragFree)
  }, [emblaApi, dragFree])

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi])

  return (
    <LegacyCarouselContainer>
      <LegacyCarouselViewport ref={emblaRef}>
        <List
          useLegacyLgDesktopBreakpoint={useLegacyLgDesktopBreakpoint}
          removeHorizontalSpacing={removeHorizontalSpacing}
          {...listCustomProps}
        >
          {slides.map((child, index) => (
            <Slide
              as={preventNestedListItem ? 'div' : 'li'}
              key={`${name}-slide-${index}`}
              slideCustomStyle={slideCustomStyle}
              slidesToShow={slidesToShow}
              useLegacyLgDesktopBreakpoint={useLegacyLgDesktopBreakpoint}
            >
              {child}
            </Slide>
          ))}
        </List>

        {shouldShowArrows && (
          <PrevNextBtnWrapper
            shouldShowArrows={shouldShowArrows}
            useLegacyLgDesktopBreakpoint={useLegacyLgDesktopBreakpoint}
          >
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </PrevNextBtnWrapper>
        )}
      </LegacyCarouselViewport>

      {scrollSnaps.length > 1 && shouldShowDots && (
        <CarouselDots data-testid="carousel-dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              index={index}
              minItems={emblaApi?.slidesInView().length || 0}
              key={`${name}-dot-${index}`}
              isSelected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </CarouselDots>
      )}
    </LegacyCarouselContainer>
  )
}
