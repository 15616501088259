/** Maps linear number to a power curve.
 * See: https://stackoverflow.com/a/17102320/103647
 *
 * Given:
 * f(1) = x
 * f(50) = y
 * f(100) = z
 *
 * Derive:
 * A = (xz - y²) / (x - 2y + z)
 * B = (y - x)² / (x - 2y + z)
 * C = 2 * log((z-y) / (y-x))
 *
 * Inverse reverses the mapping back to linear scale.
 */
export const getLinearToPowerCurveConverter = (min: number, max: number, halfPointArg?: number) => {
  const halfPoint = halfPointArg || min + max / 2
  const A = (min * max - halfPoint ** 2) / (min - 2 * halfPoint + max)
  const B = (halfPoint - min) ** 2 / (min - 2 * halfPoint + max)
  const C = 2 * Math.log((max - halfPoint) / (halfPoint - min))

  return (input: number, inverse = false) => {
    if (inverse) {
      return (Math.log((input - A) / B) / C) * 100
    }

    return Math.max(Math.min(A + B * Math.exp(C * (input / 100)), max), min)
  }
}
