import { type SearchCategory } from '@kijiji/generated/graphql-types'

import { type ApiLocale, API_LOCALE } from '@/domain/locale'
import { type TrackEventArgs, trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import {
  type AlgoliaAutosSuggestion,
  type AlgoliaGeneralSuggestion,
  type AlgoliaPathToRoot,
} from '@/types/AlgoliaSuggestions'
import { type BrandedSearchData } from '@/types/customCampaigns'
import { type SearchBarOption } from '@/ui/molecules/search-bar'
import { type SearchResultHit } from '@/utils/algolia/algoliaSearch'
import { capitalizeFirstWord, capitalizeString } from '@/utils/string'

/**
 * Returns the branded option for the search-bar component from the "Branded Search" type of campaign
 * The "Branded Search" campaign is fixed in the last option of the search-bar
 * It only appears to the user when the search keyword matches one of the possible listed keywords for the campaign
 */
export type SearchSuggestion = {
  campaignId?: number
  category?: SearchCategory
} & SearchBarOption

export const getBrandedSuggestion = (
  customCampaigns: BrandedSearchData[],
  searchQuery: string
): SearchSuggestion[] => {
  /** If there are no branded campaigns - return no options */
  if (!customCampaigns.length || !searchQuery) {
    return []
  }

  /** Check if there are any campaigns that match the typed search query */
  const keywordMatchedCampaign = customCampaigns.find(({ keywords }) => {
    return keywords.find((keyword) => {
      return searchQuery.toLowerCase().trim().includes(keyword.toLocaleLowerCase())
    })
  })

  /** If no keyword matched - return no options */
  if (!keywordMatchedCampaign) {
    return []
  }

  /** If there is a branded suggestion - should format it to a search bar option */
  const { label, logo, id } = keywordMatchedCampaign
  return [
    {
      label,
      campaignId: id,
      featuredKeyword: searchQuery,
      imgSrc: logo,
      isFeatured: true,
      value: searchQuery,
    },
  ]
}

/**
 * Separate type of search suggestions from algolia response
 * There are 2 types of algolia suggestions: General results & Autos Result
 */
export const separateSearchSuggestionByType = (
  suggestions: SearchResultHit[]
): {
  autosResults: AlgoliaAutosSuggestion[]
  generalResults: AlgoliaGeneralSuggestion[]
} => {
  /**
   * Algolia returns 2 arrays of suggestions.
   * The first array is a list of general results, the second array is the autos results.
   * Both have different data types
   */
  const algoliaGeneralResults = suggestions[0]
  const algoliaAutosResult = suggestions[1]

  /**
   * Formatting suggestions from algolia response to a simple array of objects to simplify manipulation
   * i.e. [{"0":{...}, "1": {...}}] to [{...}, {...}]
   *  */
  const generalResults = algoliaGeneralResults
    ? Object.keys(algoliaGeneralResults).reduce((acc: AlgoliaGeneralSuggestion[], curr) => {
        return [...acc, algoliaGeneralResults[curr]]
      }, [])
    : []

  const autosResults = algoliaAutosResult
    ? Object.keys(algoliaAutosResult).reduce((acc: AlgoliaAutosSuggestion[], curr) => {
        return [...acc, algoliaAutosResult[curr]]
      }, [])
    : []

  return { generalResults: generalResults || [], autosResults: autosResults || [] }
}

/**
 * Return category name from localized names
 * */
const getSuggestedCategoryName = (localizedNames: Record<string, string>, apiLocale: ApiLocale) => {
  const categoryName = localizedNames[apiLocale]

  if (apiLocale === API_LOCALE.fr_CA) {
    return capitalizeFirstWord(categoryName)
  }

  return capitalizeString(categoryName)
}

/**
 * Suggested categories are options that may contain a different category of the one being searched on
 * Ex: "Printer in Electronics" when user is searching in "buy & sell"
 * Clicking on those options should take the user directly to the search page for it
 */
const getSuggestedCategoryOptions = (
  apiLocale: ApiLocale,
  categories: AlgoliaGeneralSuggestion['categories'],
  keyword: string
): SearchBarOption[] => {
  if (!categories.length) return []

  const localizedIn = apiLocale === API_LOCALE.en_CA ? 'in' : 'dans'

  /** Return formatted suggested category options */
  return categories.map(({ localizedNames, pathToRoot }) => {
    /** Compose the suggested category label */
    const suggestedCategoryLabel = `${keyword} ${localizedIn} ${getSuggestedCategoryName(
      localizedNames,
      apiLocale
    )}`

    const leafCategory: AlgoliaPathToRoot = pathToRoot[0]
    const parentCategory: AlgoliaPathToRoot = pathToRoot[1]

    return {
      label: suggestedCategoryLabel,
      value: keyword,
      category: {
        id: parseInt(leafCategory.id),
        localizedName: leafCategory.localizedNames[apiLocale],
        parentId: parentCategory?.id ? parseInt(parentCategory.id) : null,
      },
    }
  })
}

/**
 * Returns only general algolia suggestions
 * */
export const getCategorySuggestions = (
  generalResults: AlgoliaGeneralSuggestion[],
  apiLocale: ApiLocale = API_LOCALE.en_CA
): SearchBarOption[] => {
  if (!generalResults.length) return []

  /** Get keyword and category of the first search result */
  const { completion, categories } = generalResults[0]

  /** Return category suggested options */
  const suggestedCategoryOptions = getSuggestedCategoryOptions(apiLocale, categories, completion)

  /** Transform general suggestion results into options type */
  const suggestionsOptions: SearchBarOption[] = generalResults.map((item) => ({
    label: item.completion,
    value: item.completion,
  }))

  /**
   * Insert the category option in the second element of the options array.
   */
  suggestionsOptions.splice(1, 0, ...suggestedCategoryOptions)

  return suggestionsOptions
}

/** Returns a formatted category from the selected category option if any */
export const getCategoryFromSelectedOption = (
  selectedOption?: SearchSuggestion
): SearchCategory | undefined => {
  if (!selectedOption?.category) return

  const { category } = selectedOption

  return {
    id: category.id,
    localizedName: capitalizeString(category.localizedName || ''),
    parentId: category.parentId,
  }
}

type TrackSearchSubmitArgs = {
  keyword: string
  selectedSuggestion?: SearchSuggestion | null
  options?: Pick<TrackEventArgs, 'sendToOptimizely' | 'customParameters' | 'tags'>
}

export const trackSearchSubmit = ({
  keyword,
  selectedSuggestion,
  options,
}: TrackSearchSubmitArgs) => {
  if (!keyword) return

  /**
   * Track the search attempt type and meta-data
   * Track the selected option from the dropdown if any
   * Possible suggestions: Keyword / Category / Branded Search campaign / MOVE Campaign (autos)
   * */

  if (!selectedSuggestion) {
    /** It is considered a "Organic keyword search" when user submitted a search without selecting a suggestion */
    trackEvent({
      action: GA_EVENT.SearchAttempt,
      label: `type="organic_keyword";keyword="${keyword}"`,
      ...options,
    })
    return
  }

  if (selectedSuggestion.campaignId) {
    trackEvent({
      action: GA_EVENT.SearchAttempt,
      label: `type="auto_suggest_brand";campaign_id="${selectedSuggestion.campaignId}"`,
      ...options,
    })
    return
  }

  if (selectedSuggestion.category) {
    trackEvent({
      action: GA_EVENT.SearchAttempt,
      label: `type="auto_suggest_cat";cat="${selectedSuggestion.category.id}"`,
      ...options,
    })
    return
  }
}
