import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

const MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP = 975

export const useMegaMenuCollapsedView = (debounceTime?: number) => {
  const [isCollapsedMenuView, setIsCollapsedMenuView] = useState<boolean>(false)
  const { breakpoints } = useTheme()

  /** The modal should not appear on desktop view */
  useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth < MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP) {
        setIsCollapsedMenuView(true)
      } else {
        setIsCollapsedMenuView(false)
      }
    }, debounceTime || 100)

    window.addEventListener('resize', handleResize)

    // Initial check on component mount
    handleResize()

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoints.large, debounceTime])

  return { isCollapsedMenuView }
}
