import { type GetLocationFromPlaceQuery } from '@kijiji/generated/graphql-types'

/**
 * Returns coordinates based on the LocationFromPlace response
 *
 * @param isRegion
 * @param placeLocation
 * @param location
 * @returns
 */
export const getLocationCoordinates = (
  isRegion: boolean,
  placeLocation?: NonNullable<
    NonNullable<GetLocationFromPlaceQuery['locationFromPlace']>['place']
  >['location'],
  location?: NonNullable<GetLocationFromPlaceQuery['locationFromPlace']>['location']
) => {
  if (isRegion) {
    return { latitude: null, longitude: null }
  }

  if (placeLocation?.latitude && placeLocation?.longitude) {
    return { latitude: placeLocation?.latitude, longitude: placeLocation?.longitude }
  }

  if (location?.coordinates?.latitude && location?.coordinates?.longitude) {
    return {
      latitude: location?.coordinates?.latitude,
      longitude: location?.coordinates?.longitude,
    }
  }

  return { latitude: null, longitude: null }
}
