import { type KeyboardEvent } from 'react'

import { KeyboardKeys } from '@/constants/keyboardEventKeys'

export const onEnterPress = (
  evt: KeyboardEvent,
  callback: (...ags: unknown[]) => void | Promise<void>
) => {
  if (evt.key === KeyboardKeys.ENTER) {
    callback()
  }
}

export const onEscapePress = (
  evt: KeyboardEvent,
  callback: (...ags: unknown[]) => void | Promise<void>
) => {
  if (evt.key === KeyboardKeys.ESCAPE) {
    callback()
  }
}
