export const SRP_LISTING_LIMIT = 40

export const DEVICE_TYPE = {
  MOBILE: 'MWEB',
  DESKTOP: 'DESKTOP',
} as const

export const LEGACY_HP_LG_BREAKPOINT = 1148

// Legacy sizing constants from app/helpers/banners.ts
export const LEGACY_MIN_DESKTOP = 990
export const LEGACY_MIN_TABLET = 760
export const LEGACY_MIN_TABLET_VLI = 840
