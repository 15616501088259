import Head from 'next/head'
import Script from 'next/script'

import { useScriptOptOut } from '@/hooks/useScriptOptout'

interface CoreWebVitalScriptProps {
  rumPageLabel?: string
}

export const CoreWebVitalScript = ({ rumPageLabel }: CoreWebVitalScriptProps) => {
  const rumSampleRate = 0.01

  const { shouldRenderScript } = useScriptOptOut()

  if (!shouldRenderScript('coreWebVitals')) {
    return null
  }

  return (
    <>
      {rumPageLabel && (
        <Head key="rum-page-label">
          <script dangerouslySetInnerHTML={{ __html: `window.__CWVL="${rumPageLabel}"` }}></script>
        </Head>
      )}
      {/* 1% traffic hack to send RUM data to CoreWebVitals.io */}
      {Math.random() < rumSampleRate && (
        <Script
          strategy="afterInteractive"
          src="https://rum.corewebvitals.io/cwv/65ef6d545d9f9e7ae93a82be.js"
        />
      )}
    </>
  )
}
