import styled from 'styled-components'

import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const PSABannerContainer = styled.section(
  ({ theme }) => `
  align-items: flex-start;
  background-color: ${theme.colors.purple.primary};
  border-bottom: .5rem solid ${theme.colors.green.primary};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.defaultSmall};
  justify-content: center;
  padding: ${theme.spacing.default} ${theme.spacing.husky} ${theme.spacing.default} ${
    theme.spacing.xLarge
  };
  position: relative;
  
  ${MEDIA_QUERIES(theme).medium}{
    align-items: center;
    flex-direction: row;
    gap: ${theme.spacing.large};
  }
`
)

export const PSABannerImageContainer = styled.div`
  position: relative;
  height: 3.6rem;
  width: 7.2rem;
  flex-shrink: 0;
`

// eslint-disable-next-line @kijiji/prefer-link-custom
export const PSABannerLink = styled.a(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  ${styleFromTypography(theme.typography.body.small)}
  background: ${theme.colors.violet.primary};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.white};
  margin-top: ${theme.spacing.default};
  padding: .6rem 1rem;
  text-decoration: none;

  &:hover {
    background: ${theme.colors.green.dark1};
  }

  ${MEDIA_QUERIES(theme).medium}{
    margin-top: 0;
  }
`
)

export const PSABannerBody = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.mini};

  & p {
    font-weight: 400;
  }
`
)

// Custom spacing has been used to match legacy
// eslint-disable-next-line @kijiji/prefer-button-component
export const PSABannerCloseButton = styled.button(
  ({ theme }) => `
  color: ${theme.colors.white};
  position: absolute;
  height: ${theme.spacing.large};
  width: ${theme.spacing.large};
  right: 1rem;
  top: 1rem;

  & svg {
    height: 2rem;
    width: 2rem;
  }
`
)
