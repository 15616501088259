import { type LinkProps } from 'next/link'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import { type ParsedUrlQueryInput } from 'querystring'
import React, { type PropsWithChildren, type ReactElement } from 'react'

import { type Route } from '@/constants/routes'

export type RouteLinkProps = {
  route: Route
  query?: string | null | ParsedUrlQueryInput
} & Partial<PropsWithChildren<LinkProps>>

/**
 * Wrapper to replace next/link "Link" component while webapp is in the middle of a migration.
 *
 * This component contains all the logic involved in deciding which href to render and in which way to render links depending if is a webapp link or a legacy one.
 *
 * @usage This component should always be wrapping one anchor tag
 */
export const RouteLink = ({
  children,
  route,
  query,
  ...rest
}: Omit<RouteLinkProps, 'passHref'>) => {
  const { href = '/', legacyHref, useLegacyHref } = route
  const { t } = useTranslation('routes')

  /**
   * While on migration, the Legacy URLs need default <a> tag without being wrapped in NextJS Link.
   * Those won't be automatically pre-fetched.
   */
  if (useLegacyHref) {
    /**
     * When dealing with a legacy link, RouteLink will only return it's children, without being wrapped in the nextJS Link component.
     *
     * Since href is declared and passed forwarded by the nextJS Link component (i.e. <Link href="/" passRef>{children}</Link>), "children" won't have this href definition when being returned on its own (ie. <>{children}</>).
     *
     * The solution used: Clone children and append the correct legacy href.
     * This way, the component that is using RouterLink doesn't need to know about legacy vs webapp href.
     */
    return (
      <>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child as ReactElement<{ href: string }>, {
            href: t(legacyHref || '/'),
          })
        })}
      </>
    )
  }

  /**
   * Link has prefetch as default
   * If the Link is supposed to be webapp one, it will always prefetch unless it is passed through props (i.e. <RouteLink prefetch=false>)
   */

  const parsedHref =
    typeof query === 'string'
      ? `${t(href)}?${query}`
      : qs.stringifyUrl({ url: t(href), query: query ?? {} })

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as ReactElement<{ href: string }>, {
          ...rest,
          href: parsedHref,
        })
      })}
    </>
  )
}
