import { HTMLAttributes, RefObject } from 'react'

import { useHasValue } from '@/ui/hooks/useHasValue'
import { useIsFocused } from '@/ui/hooks/useIsFocused'

import { FieldLabel as LabelComponent } from './styled'

export type FloatingLabelProps = {
  /**
   * Defines if input it refers to is invalid
   */
  hasError?: boolean
  /**
   * Required attribute that makes the connection with the input.
   */
  htmlFor: string
  /**
   * Id of the label (if needed for accessibility)
   */
  id?: string
  /**
   * References the input or textarea element and used to move the label to the top of the input.
   */
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>
  /**
   * to be used to force the focus of the input attached to the label.
   */
  isFocused?: boolean
} & HTMLAttributes<HTMLLabelElement>

/**
 * @description Floating label component to be used with text inputs
 */
export const FloatingLabel = ({
  children,
  hasError,
  htmlFor,
  id,
  inputRef,
  isFocused: forceIsFocused,
  ...rest
}: FloatingLabelProps) => {
  const isFocused: boolean =
    (inputRef && useIsFocused(inputRef)) || !!forceIsFocused
  const hasValue: boolean = inputRef && useHasValue(inputRef)

  return (
    <LabelComponent
      $hasError={!!hasError}
      $isFocused={isFocused || hasValue}
      htmlFor={htmlFor}
      id={id}
      {...rest}
    >
      {children}
    </LabelComponent>
  )
}
