import { ReactNode } from 'react'

import { CommonInputProps } from '@/ui/typings/commonTextInput'

import { CheckboxContainer, CheckboxLabel, StyledCheckbox } from './styled'

export type CheckboxProps = {
  /**
   * Label text
   */
  label: string | ReactNode
} & Omit<CommonInputProps, 'maxLength'>

/**
 * @description Simple checkbox component
 */
export const Checkbox = ({ errorId, id, label, ...rest }: CheckboxProps) => {
  return (
    <CheckboxContainer key={`checkbox-${id}`}>
      <StyledCheckbox
        aria-describedby={errorId}
        aria-invalid={!!errorId}
        data-testid={`checkbox-${id}`}
        id={id}
        type="checkbox"
        {...rest}
      />
      <CheckboxLabel htmlFor={id} data-testid={`checkbox-label-${id}`}>
        {label}
      </CheckboxLabel>
    </CheckboxContainer>
  )
}
