import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLocationServiceOff: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.44 19a.76.76 0 01-.71-.58l-1.38-5.74-5.77-1.38a.76.76 0 01-.06-1.46L18 5.05a.75.75 0 011 1l-4.8 12.49a.75.75 0 01-.76.46zm-6.88-8.49l5.14 1.23a.76.76 0 01.56.56l1.23 5.14 4.33-11.26-11.26 4.33z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgLocationServiceOff
