import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import {
  ConnectionWrapper,
  LanguageSwitch,
  TextContainer,
} from '@/components/shared/global-header/tools/logged-out-links/styled'
import { HiddenMobile } from '@/components/shared/global-header/tools/styled'
import { RouteLink } from '@/components/shared/route-link'
import { ToggleLocale } from '@/components/shared/toggle-locale'
import { ROUTES } from '@/constants/routes'
import { trackGlobalHeaderLanguageSwitch } from '@/domain/globalHeader'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { TextLink } from '@/ui/atoms/text-link'
import { isServer } from '@/utils/isSSR'

export const LoggedOutLinks = () => {
  const { t } = useTranslation(['global_header', 'common'])
  const { asPath } = useRouter()
  const { routeLocale } = useLocale()
  const [goToSignIn, setGoToSignIn] = useState(false)

  /** Get the redirect url user will return to once they finish registering */
  const getRedirectQuery = () => {
    /**
     * Should only attach redirect query on register URL if:
     * - user is not coming from the homepage
     * - user is not coming from register page
     */
    const shouldHaveRedirectQuery =
      !asPath.includes('register') &&
      asPath !== '/' &&
      asPath !== '/home' &&
      !asPath.includes('/h-')

    if (!shouldHaveRedirectQuery) {
      return undefined
    }

    /**
     * Should not attach multiple redirect queries
     */
    return { redirect: !isServer() ? encodeURIComponent(window.location.href) : '/' }
  }

  const trackRegisterBegin = () => {
    trackEvent({ action: GA_EVENT.UserRegistrationBegin, label: GA_CTA_HEADER })
  }

  const handleSignInClick = () => {
    trackEvent({ action: GA_EVENT.LoginBegin, label: GA_CTA_HEADER })
    setGoToSignIn(true)
  }

  useSignin({ callbackUrl: asPath, goToSignIn })

  return (
    <>
      <ToggleLocale onClick={() => trackGlobalHeaderLanguageSwitch(routeLocale)}>
        <LanguageSwitch>{t('common:language_toggle.text.short')}</LanguageSwitch>
      </ToggleLocale>

      <ConnectionWrapper>
        <HiddenMobile>
          <RouteLink prefetch={false} query={getRedirectQuery()} route={ROUTES.REGISTER}>
            <TextLink
              onClick={trackRegisterBegin}
              variant="secondary"
              rel="nofollow"
              data-testid="header-register"
            >
              {t('global_header:links.register.label')}
            </TextLink>
          </RouteLink>

          <TextContainer>{t('global_header:or')}</TextContainer>
        </HiddenMobile>

        <TextLink
          as="button"
          key="sign_in"
          variant="secondary"
          onClick={handleSignInClick}
          data-testid="header-sign-in"
        >
          {t('global_header:links.sign_in.label')}
        </TextLink>
      </ConnectionWrapper>
    </>
  )
}
