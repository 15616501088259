import styled from 'styled-components'

import { Label } from '@/ui/atoms/label'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'

const CHECKBOX_SIZE = '2rem'

export const CheckboxContainer = styled.div`
  display: flex;
  position: relative;
`

export const CheckboxLabel = styled(Label)(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  cursor: pointer;

  &::before {
    ${TRANSITION_STYLE}
    content: '';
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};
    border: .1rem solid ${theme.colors.grey.light3};
    box-sizing: border-box;
    display: flex;
    height: ${CHECKBOX_SIZE};
    justify-content: center;
    left: 0;
    position: absolute;
    width: ${CHECKBOX_SIZE};
  }

  &:hover::before {
    background-color: ${theme.colors.purple.light5};
  }
`
)

export const StyledCheckbox = styled.input(
  ({ theme }) => `
  height: ${CHECKBOX_SIZE};
  margin-right: ${theme.spacing.defaultSmall};
  opacity: 0;
  min-width: ${CHECKBOX_SIZE};

  // Checked Style

  &:checked + label::before {
    background-color: ${theme.colors.purple.light2};
    color: ${theme.colors.white};
    content: '✓';
  }

  &:not([aria-invalid='true']) {
    // Focus Style

    &:focus:not(:checked) + label::before {
      border-color: ${theme.colors.purple.light1};
      box-shadow: 0 0 .5rem rgba(55, 51, 115, 0.5);
    }

    &:focus:checked + label::before {
      background-color: ${theme.colors.purple.light2};
      border-color: ${theme.colors.purple.dark1};
      box-shadow: none;
    }  
  }

  // Disabled Styles

  &:disabled + label {
    cursor: not-allowed;
  }

  &:disabled + label::before {
    background-color: ${theme.colors.grey.light4};
    border-color: ${theme.colors.grey.light3};
    color: ${theme.colors.grey.light2};
  }

  // Error Styles

  &[aria-invalid='true']:not(:disabled) {
    & + label::before {
      background-color: ${theme.colors.red.light5};
      border-color: ${theme.colors.red.light2};
      color: ${theme.colors.red.light2};
    }

    &:focus + label::before {
      border-color: ${theme.colors.red.primary};
      box-shadow: 0 0 .5rem rgba(241, 69, 79, 0.5);
    }

    &:hover + label::before {
      background-color: ${theme.colors.red.light4};
      border-color: ${theme.colors.red.light2};
    }
  }
`
)
