import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLocationOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.6 21s-1.9-1.6-3.8-3.9S5 12 5 9.5c0-2.2.9-4.1 2.2-5.4C8.7 2.7 10.6 2 12.6 2c2 0 3.9.8 5.2 2.1C19.2 5.4 20 7.3 20 9.5c0 2.5-1.9 5.4-3.8 7.6-1.9 2.2-3.6 3.9-3.6 3.9z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6 8.5c0-.8-.3-1.6-.9-2.1-.5-.6-1.3-.9-2.1-.9s-1.6.3-2.1.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.1c.5.6 1.3.9 2.1.9"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgLocationOutline
