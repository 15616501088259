import { RefObject, useEffect, useState } from 'react'

export function useHasValue(
  ref: RefObject<HTMLInputElement | HTMLTextAreaElement>
) {
  const [hasValue, setHasValue] = useState(false)

  useEffect(() => {
    if (ref.current?.value) {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
  }, [ref])

  return hasValue
}
