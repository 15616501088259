import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import { GetSearchKeywordDocument } from '@kijiji/generated/graphql-types'

import { initializeApollo } from '@/lib/apollo/apolloClient'

export const updateSearchKeywordCache = (
  keyword?: string,
  client?: ApolloClient<NormalizedCacheObject>
) => {
  if (!client) {
    client = initializeApollo()
  }

  client.cache.writeQuery({
    query: GetSearchKeywordDocument,
    data: { srp: { searchKeyword: keyword ?? null } },
  })
}
