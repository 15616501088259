import CloseIcon from '@kijiji/icons/src/icons/Close'
import ErrorIcon from '@kijiji/icons/src/icons/Error'
import InformationIcon from '@kijiji/icons/src/icons/Information'
import SuccessIcon from '@kijiji/icons/src/icons/Success'
import WarningSolidIcon from '@kijiji/icons/src/icons/WarningSolid'
import { HTMLAttributes, ReactNode, useState } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { ListItem } from '@/ui/atoms/list-item'

import {
  colorByVariation,
  SystemMessageCloseButton,
  SystemMessageContainer,
  SystemMessageTextContainer,
} from './styled'

type CloseProps =
  | {
      /**
       * Boolean to toggle off/on the close/dismiss button
       */
      hideCloseButton?: false
      /**
       * Defines aria label for close icon button, cannot be passed if hideCloseButton is true
       */
      closeButtonLabel: string
      /**
       * A callback option to fire on dismiss of the message, cannot be passed if hideCloseButton is true
       */
      onClose?: () => void
    }
  | { hideCloseButton: true; closeButtonLabel?: never; onClose?: never }

export type SystemMessageProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Defines a different icon for the banner
   */
  bannerIcon?: ReactNode
  /**
   * Description to be shown in the banner
   */
  description?: string | ReactNode
  /**
   * List of text to be shown in the banner
   */
  descriptionList?: (string | ReactNode)[]
  /**
   * Title of the banner
   */
  title?: string
  /**
   * Defines the style variation of the banner
   */
  variation: 'error' | 'success' | 'tip' | 'warning'
} & CloseProps

const systemMessageIcon: {
  [x in SystemMessageProps['variation']]: ReactNode
} = {
  error: <ErrorIcon aria-hidden />,
  success: <SuccessIcon aria-hidden />,
  tip: <InformationIcon aria-hidden />,
  warning: <WarningSolidIcon aria-hidden />,
}

/**
 * @description System message with icon and text
 */

export const SystemMessage = ({
  variation,
  title,
  descriptionList,
  description,
  hideCloseButton = false,
  closeButtonLabel,
  bannerIcon,
  onClose,
}: SystemMessageProps) => {
  const theme = useTheme()
  const [isDismissed, setIsDismissed] = useState(false)

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }

    setIsDismissed(true)
  }

  const renderDescription = () => {
    if (typeof description === 'string') {
      return (
        <BodyText
          color={theme.colors.grey.light1}
          data-testid="system-message-description"
        >
          {description}
        </BodyText>
      )
    }

    return description ?? null
  }

  return isDismissed ? null : (
    <SystemMessageContainer
      $variation={variation}
      descriptionList={!!descriptionList?.length}
      aria-live="polite"
      role="alert"
    >
      <div className="system-message-icon">
        {bannerIcon || systemMessageIcon[variation]}
      </div>

      <SystemMessageTextContainer data-testid="system-message-container">
        {title ? (
          <BodyText
            color={theme.colors.grey.primary}
            data-testid="system-message-title"
          >
            {title}
          </BodyText>
        ) : null}

        {renderDescription()}

        {descriptionList?.length ? (
          <ul>
            {descriptionList?.map((item, i) => (
              <ListItem
                bulletColor={theme.colors[colorByVariation[variation]].primary}
                size="medium"
                variant="bullet"
                key={i}
              >
                {item}
              </ListItem>
            ))}
          </ul>
        ) : null}
      </SystemMessageTextContainer>

      {!hideCloseButton && (
        <SystemMessageCloseButton
          aria-label={closeButtonLabel}
          onClick={handleOnClose}
          description={!!(descriptionList?.length || description)}
          data-testid="system-message-close-button"
        >
          <CloseIcon aria-hidden />
        </SystemMessageCloseButton>
      )}
    </SystemMessageContainer>
  )
}
