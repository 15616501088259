import useCookiePreferences from '@/hooks/useCookiePreferences'

/*
  Description: This hook is used to determine whether or not to render a script based on the user's cookie preferences.

  Whenever a script is added to the site, ask if should be added to either the TARGET_OPT_OUT or ANALYTICS_OPT_OUT array
  based on the recommendation of the privacy team. This hook will then check the user's cookie preferences to determine
  if the script should be rendered or not, thus preventing the script from running and collecting
  data and behaviors.

  Note: we are not including GPT.js and GTM.js as they have different customized approaches to user consent. 
*/

// add opt out script names here
const TARGET_OPT_OUT = ['prebid', 'amazonTAM'] as const
const ANALYTICS_OPT_OUT = [
  'adobeAudienceManager',
  'clarivoy',
  'speedCurve',
  'coreWebVitals',
] as const

type ScriptName = (typeof TARGET_OPT_OUT)[number] | (typeof ANALYTICS_OPT_OUT)[number]

type ScriptOptOutResponse = {
  shouldRenderScript: (scriptName: ScriptName) => boolean
}

export const useScriptOptOut = (): ScriptOptOutResponse => {
  const { hasUserOptedOutAnalytics, hasUserOptedOutTargeting } = useCookiePreferences()

  const shouldRenderScript = (scriptName: ScriptName) => {
    if (
      TARGET_OPT_OUT.includes(scriptName as (typeof TARGET_OPT_OUT)[number]) &&
      hasUserOptedOutTargeting
    ) {
      return false
    }

    if (
      ANALYTICS_OPT_OUT.includes(scriptName as (typeof ANALYTICS_OPT_OUT)[number]) &&
      hasUserOptedOutAnalytics
    ) {
      return false
    }

    return true
  }

  return { shouldRenderScript }
}
