import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUserOutline: React.FC<IconProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M17.057 15.224a.5.5 0 00-.206.979c1.586.334 2.149.647 2.149 1.734V19H5v-1.063c0-1.087.563-1.4 2.147-1.734l3.454-.713a.495.495 0 00.172-.074.466.466 0 00.044-.038.492.492 0 00.085-.087.48.48 0 00.088-.236A.477.477 0 0011 15v-1.85a.5.5 0 00-.284-.451 3.078 3.078 0 01-1.78-2.739V8.023A2.97 2.97 0 0111.845 5h.312a2.97 2.97 0 012.907 3.023V9.96a3.078 3.078 0 01-1.779 2.739.5.5 0 00.432.902 4.084 4.084 0 002.347-3.64V8.022A3.971 3.971 0 0012.156 4h-.312a3.971 3.971 0 00-3.907 4.023V9.96A4.07 4.07 0 0010 13.451v1.142l-3.057.631C5.7 15.486 4 15.844 4 17.937V19.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-1.563c0-2.092-1.7-2.451-2.943-2.713z"
      />
    </Icon>
  )
}
export default SvgUserOutline
