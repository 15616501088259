import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFavouriteOutlineThin: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.221 5.914c-1.605-1.686-4.413-1.785-6.118-.198l-.2.198-.903.794-.903-.893C9.493 4.13 6.684 4.03 4.98 5.715l-.2.2c-1.705 1.784-1.705 4.66 0 6.445l6.82 6.942c.1.099.2.198.401.198.1 0 .3 0 .401-.1l6.82-6.94c1.705-1.885 1.705-4.76 0-6.546z"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgFavouriteOutlineThin
