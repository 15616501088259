import styled from 'styled-components'

import { StyledTextInput } from '@/ui/atoms/text-input/styled'

import { FormControlInput } from '../form-control'

export const Input = styled(StyledTextInput)``

type SearchBarWrapperProps = { isFocused?: boolean }

type StyledFormControlInputProps = {
  $isFocused?: boolean
  $isGrouped?: boolean
  isHeaderSimplified?: boolean
}

export const StyledFormControlInput = styled(
  FormControlInput
)<StyledFormControlInputProps>(
  ({ $isFocused, $isGrouped, isHeaderSimplified }) => `
  ${$isFocused || $isGrouped ? `border-color: transparent;` : ''}
  ${
    isHeaderSimplified
      ? `
    gap: 1rem;
    padding-right: 0;
    `
      : ''
  }
`
)

export const SearchBarWrapper = styled.div<SearchBarWrapperProps>(
  ({ isFocused, theme }) => `
  & > div {
    border: .1rem solid transparent;
  }

  ${
    isFocused
      ? `
    & > div {
      border-color: ${theme.colors.blue.primary};
      border-radius: ${theme.borderRadius.small};
      box-shadow: 0 0 .8rem rgba(38, 129, 219, 0.5);
      overflow: hidden;
    }

    & > div > div[aria-expanded="true"] > div {
      border-radius: ${theme.borderRadius.small};
      border-bottom-color: ${theme.colors.blue.primary};
    }
  `
      : ''
  }
`
)

type SearchResultsContainerProps = {
  zIndex?: number
}

export const SearchResults = styled.div<SearchResultsContainerProps>(
  ({ theme, zIndex }) => `
  background-color: ${theme.colors.white};
  z-index: ${zIndex ?? 'auto'};
`
)

type SearchListProps = {
  maxHeight: string
}

export const SearchList = styled.ul<SearchListProps>(
  ({ maxHeight, theme }) => `
  list-style: none;
  padding: 0;

  & > div {
    max-height: ${maxHeight};
    overflow-y: auto;

    &:first-of-type {
      border-top: .1rem solid ${theme.colors.blue.primary};
    }
  }
`
)

type SearchListElementProps = {
  isHighlighted: boolean
  hasDivider?: boolean
}

export const SearchListElement = styled.li<SearchListElementProps>(
  ({ theme, isHighlighted, hasDivider }) => `
  background-color: ${
    isHighlighted ? theme.colors.grey.light4 : theme.colors.white
  };
  border-top: ${
    hasDivider ? `.1rem solid ${theme.colors.grey.light4}` : 'none'
  };
  cursor: pointer;
  
  & > button,
  & > a {
    display: flex;
    padding: 1.2rem ${theme.spacing.default};
    text-decoration: none;
    width: 100%;
  }
`
)

export const LeftIconWrapper = styled.span(
  ({ theme }) => `
  margin-right: 2rem;

  & svg {
    flex-shrink: 0;
    height: auto;
    width: ${theme.spacing.large};
  }
`
)

export const RightIconWrapper = styled.span(
  ({ theme }) => `
  height: ${theme.spacing.default};
  margin-left: ${theme.spacing.defaultSmall};

  & svg {
    height: ${theme.spacing.default};
    width: 7.8rem;
  }
`
)

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const FeaturedElementImage = styled.img(
  ({ theme }) => `
  height: 2rem;
  margin-right: ${theme.spacing.defaultSmall};
  width: auto;
`
)

/**
 * Necessary to access styled "as" prop
 */
export const SearchListButton = styled.button`
  align-items: center;
  display: flex;
`
