import styled from 'styled-components'

// z-index is set to the highest used value in frontend service (FES)
export const StyledSkipNav = styled.nav(
  ({ theme }) => `
  a {
    border: 0;
    clip-path: rect(0 0 0 0);
    height: 0.1rem;
    width: 0.1rem;
    padding: 0;
    overflow: hidden;
    position: absolute;
    &:focus {
      padding: 1rem;
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: auto;
      height: auto;
      clip: auto;
      text-decoration: none;
      color: ${theme.colors.white};
      background: ${theme.colors.purple.light1};
      z-index: 100000002
    }
  }`
)
