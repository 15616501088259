import styled from 'styled-components'

import { COMMON_INPUT_STYLE } from '@/ui/constants/commonFormStyle'

/**
 * @description Simple input field
 */

export const StyledTextInput = styled.input(
  ({ theme }) => `
  ${COMMON_INPUT_STYLE(theme)}
  position: relative;
  z-index: 8;

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
`
)
