import styled from 'styled-components'

import {
  INPUT_ERROR_COLOR,
  INPUT_HORIZONTAL_PADDING,
} from '@/ui/constants/commonFormStyle'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { FloatingLabelProps } from './FloatingLabel'

type StyledLabelProps = RequiredPickMap<FloatingLabelProps, 'hasError'> & {
  $isFocused?: boolean
}

export const FieldLabel = styled.label<StyledLabelProps>(
  ({ theme, $isFocused, $hasError }) => `
    ${TRANSITION_STYLE}
    color: ${$hasError ? INPUT_ERROR_COLOR(theme) : theme.colors.grey.light1};
    font-family: inherit;
    left: ${INPUT_HORIZONTAL_PADDING};
    pointer-events: auto;
    position: absolute;
    text-overflow: ellipsis;
    top: ${$isFocused ? '0.6rem' : '1.2rem'};
    user-select: none;
    white-space: nowrap;
    z-index: 10;
    ${
      $isFocused
        ? styleFromTypography(theme.typography.body.xSmall)
        : styleFromTypography(theme.typography.body.medium)
    }
  `
)
