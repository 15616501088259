import { useGetCampaignsQuery } from '@kijiji/generated/graphql-types'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { getCustomCampaignPlatform, parseCampaigns } from '@/domain/customCampaigns'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { type BrandedSearchData, CustomCampaignName } from '@/types/customCampaigns'
import { sendToLogger } from '@/utils/sendToLogger'

type BrandedSearchResponse = {
  campaigns: BrandedSearchData[]
}

type BrandedSearchCampaignProps = {
  filterAmount?: number
  categoryId?: number
}

export const useBrandedSearchCampaign = (
  props?: BrandedSearchCampaignProps
): BrandedSearchResponse => {
  const { categoryId = ALL_CATEGORIES_ID_NUM, filterAmount = 0 } = props || {}

  const { location } = useGetLocation()
  const platform = getCustomCampaignPlatform()

  const { data } = useGetCampaignsQuery({
    skip: !isValidLocation(location.id),
    fetchPolicy: 'cache-first',
    variables: {
      categoryId,
      customType: CustomCampaignName.BRANDED_SEARCH,
      filterAmount,
      locationId: location.id,
      placement: 'custom',
      platform,
    },
    onError: (err) => {
      sendToLogger(err, { fingerprint: ['useBrandedSearchCampaign'] })
    },
  })

  /**
   * The custom campaigns are returned as a string and will need to be parsed to the expected
   * format for the campaign requested
   */
  const campaigns = parseCampaigns<BrandedSearchData>({ campaigns: data?.campaigns, filterAmount })

  return { campaigns }
}
