import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'

/**
 * Rounds given coordinate to 6 decimal places
 * @param coordinate latitude or longitude
 * @returns Rounded coordinate to six decimal places
 */
const roundLatLng = (coordinate: number): number => {
  if (!coordinate || typeof coordinate !== 'number') {
    return coordinate
  }

  return parseFloat(coordinate.toFixed(6))
}

/**
 * Checks if given two recent locations are equivalent
 * @param location Recent Location Object
 * @param otherLocation Second Recent Location Object
 * @returns True if the two locations are the same, false otherwise
 *
 * They are not equivalent if:
 * 1. They have dissimilar value for isSearchAllArea or isProvinceOrCountry
 * 2. They have similar value for isSearchAllArea or isProvinceOrCountry but different internalId
 * 3. They are radial location and value for any coorindate is undefined
 * 4. They are radial location and rounded value for corresponding coordinates is different
 */
export const isDuplicateRecentSearchLocation = (
  location: RecentSearchLocation,
  otherLocation: RecentSearchLocation
) => {
  /* Not equivalent if dissimilar value for isSearchAllArea or isProvinceOrCountry */
  if (
    location.isSearchAllArea !== otherLocation.isSearchAllArea ||
    location.isProvinceOrCountry !== otherLocation.isProvinceOrCountry
  ) {
    return false
  }

  /* Equivalent if similar value for isSearchAllArea or isProvinceOrCountry and same internalId */
  if (
    (location.isSearchAllArea && otherLocation.isSearchAllArea) ||
    (location.isProvinceOrCountry && otherLocation.isProvinceOrCountry)
  ) {
    return location.internalId === otherLocation.internalId
  }

  /* They have to be radial location at this point. Not equivalent if value for any coorindate is undefined */
  if (
    location.latitude === undefined ||
    otherLocation.latitude === undefined ||
    location.longitude === undefined ||
    otherLocation.longitude === undefined
  ) {
    return false
  }

  /* Finally, check if the rounded value for corresponding coordinates is the same */
  return (
    roundLatLng(location.latitude) === roundLatLng(otherLocation.latitude) &&
    roundLatLng(location.longitude) === roundLatLng(otherLocation.longitude)
  )
}
