import { RefObject, useEffect, useState } from 'react'

export function useIsFocused(ref: RefObject<HTMLElement>) {
  const [isFocused, setIsFocused] = useState(false)

  // Set the isFocused state when the ref is focused
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('focus', () => setIsFocused(true))
      ref.current.addEventListener('blur', () => setIsFocused(false))
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('focus', () => setIsFocused(true))
        ref.current.removeEventListener('blur', () => setIsFocused(false))
      }
    }
  }, [ref])

  return isFocused
}
