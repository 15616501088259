import { type RefObject, useEffect } from 'react'

const assertIsNode = (eventTarget: EventTarget | null): void => {
  if (!eventTarget || !('nodeType' in eventTarget)) {
    throw new Error(`Node expected`)
  }
}

/**
 * Used for firing a function when a click external to the passed ref is detected
 */
export const useClickOutside = (ref: RefObject<HTMLElement>, onClickOut: () => void) => {
  useEffect(() => {
    /**
     * Fire function onClick outside of ref
     */
    const handleClickOutside = (event: MouseEvent) => {
      assertIsNode(event.target)

      const node = event.target as Node
      if (ref.current && !ref.current.contains(node)) {
        onClickOut()
      }
    }

    // Bind event listener to document
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind event listener from document
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOut])
}
