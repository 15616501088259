import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKijijiLogoMobile: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 55.67 91.5" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32.24 55.74l21.61-25.57a1.64 1.64 0 00-1.25-2.72H36.34a1.64 1.64 0 00-1.28.61l-17.86 22a.48.48 0 01-.85-.3V2.47A1.64 1.64 0 0014.71.83L1.64 2.75C.63 2.92 0 3.48 0 4.39v84.42a1.64 1.64 0 001.64 1.64h13.08a1.64 1.64 0 001.64-1.64V63.17a.51.51 0 01.93-.29l18.79 26.87a1.64 1.64 0 001.35.7h16a1.64 1.64 0 001.31-2.64L32.23 56.17a.37.37 0 01.01-.43z"
        fill="#373373"
      />
    </Icon>
  )
}
export default SvgKijijiLogoMobile
