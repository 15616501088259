import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRecent: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 7.999a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h3a.5.5 0 000-1H12v-3.5a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
      <path
        d="M19.371 8.884a8.03 8.03 0 00-4.257-4.256 8.057 8.057 0 00-6.228 0A7.994 7.994 0 004 11.998a.5.5 0 001 0 7.01 7.01 0 014.275-6.45 7.058 7.058 0 015.45 0 7.004 7.004 0 010 12.9 7.058 7.058 0 01-5.45 0 6.984 6.984 0 01-2.225-1.5.5.5 0 00-.707.707 8.002 8.002 0 0013.028-8.77z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgRecent
