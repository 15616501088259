import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const unreadCountSize = '2rem'

export const NotificationsWrapper = styled.div(
  ({ theme }) => `
  display: none;

  ${MEDIA_QUERIES(theme).small} {
    display: block;
  }
`
)

//Non-standardized spacing was used to ensure that the NWA and legacy headers match
// eslint-disable-next-line @kijiji/prefer-link-custom
export const IconLink = styled.a(
  ({ theme }) => `
  color: ${theme.colors.purple.light1};
  display: block;
  position: relative;
  transition: transform 150ms ease-in-out;
  
  &:hover, &:focus {
    transform: translateY(-3px)
  }

  & > svg {
    height: 3.6rem;
    width: 3.6rem;
  }
`
)

export const UnreadCountBadge = styled.div(
  ({ theme }) => `
  height: ${unreadCountSize};
  width: ${unreadCountSize};
  border-radius: ${theme.borderRadius.rounded};
  background-color: ${theme.colors.red.primary};
  color: ${theme.colors.white};
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  line-height: ${unreadCountSize};
`
)
