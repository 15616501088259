import ChevronIcon from '@kijiji/icons/src/icons/Chevron'
import styled from 'styled-components'

import { RequiredPickMap } from '@/ui/typings/helpers'

import { RotatingChevronProps } from './RotatingChevron'

type ChevronProps = RequiredPickMap<
  RotatingChevronProps,
  'isOpen' | 'bottom' | 'top' | 'left' | 'right'
>

export const Chevron = styled(ChevronIcon)<ChevronProps>(
  ({ $isOpen, $bottom, $top, $left, $right, theme }) => `
  height: ${theme.spacing.default};
  width: ${theme.spacing.default};
  margin: ${$top} ${$right} ${$bottom} ${$left};
  transform: ${$isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
  stroke: ${$isOpen ? theme.colors.purple.primary : theme.colors.grey.light1};
  transition: transform 0.4s, stroke 0.4s;
`
)
